import { HeroSectionBox } from "./HeroSection.styles";
import { FDPButton } from "../../../../components";
import EventImg from "../../../../assets/bg/Events-Manage-event.svg";
import DashboardImg from "../../../../assets/bg/Dashboard.svg";
import VendorImg from "../../../../assets/bg/vendor-hero-bg.svg";
import * as React from "react";
import { Fade, Zoom } from "@mui/material";
import { SIGN_UP_URL } from "../../../../helpers/routes.url";
import { TSelectableMode } from "../../../../types";

interface IProps {
  selectedMode: TSelectableMode;
}

export const HeroSection: React.FC<IProps> = ({ selectedMode }) => {
  const transitionIn = selectedMode === "Convener" ? true : true;

  return (
    <HeroSectionBox>
      <div
        className={`${
          selectedMode === "Vendor"
            ? "vendorHeaderHeight"
            : "convenerHeaderHeight"
        }
            
            `}
      >
        <div className="container">
          <div className="textHolder">
            <h2 className="title">
              {selectedMode === "Convener" ? (
                <>
                  The leading event management platform for corporate events in
                  Africa
                </>
              ) : (
                <>
                  Showcase your services, reach clients, and boost your business
                </>
              )}
            </h2>

            <div className="d-flex justify-content-center gap-4 my-5">
              {selectedMode === "Convener" ? (
                <>
                  <FDPButton
                    text="Get Started"
                    btnVariant="Primary"
                    link={SIGN_UP_URL}
                    forRouting
                  />
                  <FDPButton text="Book a Demo" btnVariant="Secondary" />
                </>
              ) : (
                <FDPButton
                  text="Get Started for free"
                  btnVariant="Primary"
                  link={SIGN_UP_URL}
                  forRouting
                />
              )}
            </div>
          </div>

          <Zoom timeout={1000} in={transitionIn} appear={transitionIn}>
            {selectedMode === "Convener" ? (
              <div
                className={`${
                  selectedMode === "Convener" ? "visible" : "hidden"
                } imgContainer smoothTrans`}
              >
                <img
                  src={EventImg}
                  alt="event management"
                  className="img1 img-fluid"
                />
                <img
                  src={DashboardImg}
                  alt="event management"
                  className="img2 img-fluid"
                />
              </div>
            ) : (
              <div
                className={`${
                  selectedMode === "Vendor" ? "visible" : "hidden"
                } d-flex justify-content-center smoothTrans`}
              >
                <img
                  src={VendorImg}
                  alt="Vendor management"
                  className="img-fluid"
                />
              </div>
            )}
          </Zoom>
        </div>
      </div>
    </HeroSectionBox>
  );
};
