import * as React from "react"
import { ComplianceBox } from "./compliance.styles"
import { Footer } from "pages/Website/components"
import { ScrollToTop } from "components"

export const TermsOfUse = () => {
  return (
    <React.Fragment>
      <ScrollToTop />
      <ComplianceBox className="container">
        <header className="headerSection">
          <h3 className="title">Terms of Use</h3>
        </header>

        <section className="sectionText">
          <p className="detail">
            Welcome to <a href="/">Frondesq</a>. These Terms of Use ("Terms")
            govern your use of our self-service event management and ticketing
            platform ("Platform") provided by OneID Global Technologies Ltd, a
            company registered in Nigeria with RC No. 1809763 ("Frondesq", "we",
            "us", or "our").
          </p>
          <p className="detail">
            This T&C represents an agreement between YOU; and
          </p>
          <p className="detail">
            OneID Global Technologies Ltd (hereinafter referred to as “Frondesq”
            or “We” or “Us”, which expression shall, where the context so
            admits, include its legal representatives and assigns); By accessing
            or using our Platform, you agree to comply with and be bound by
            these Terms. Please read them carefully.
          </p>
        </section>

        <section className="sectionText">
          <h5 className="title">1. Acceptance of Terms</h5>
          <p className="detail">
            By using our Platform, you acknowledge that you have read,
            understood, and agree to be bound by these Terms, as well as our
            Privacy Policy, which is incorporated into these Terms by reference.
            If you do not agree to these Terms, you must not use our Platform.
          </p>
        </section>

        <section className="sectionText">
          <h5 className="title">2. Platform Description</h5>
          <p className="detail">
            Frondesq provides a self-service platform for event organizers
            ("Event Organizers") to create, manage, and sell tickets to their
            events. Event attendees ("Attendees") can purchase tickets and
            access event information through our Platform.
          </p>
        </section>

        <section className="sectionText">
          <h5 className="title">3. Account Registration</h5>
          <p className="detail">
            <b>3.1 Eligibility:</b> To use our Platform, you must be at least 18
            years old and capable of forming a legally binding contract.
          </p>

          <p className="detail">
            <b>3.2 Account Creation:</b> To access certain features of our
            Platform, you may need to create an account. You agree to provide
            accurate and complete information during the registration process
            and to update such information as necessary.
          </p>

          <p className="detail">
            <b>3.3 Account Security:</b> You are responsible for maintaining the
            confidentiality of your account login credentials and for all
            activities that occur under your account. You agree to notify us
            immediately of any unauthorized use of your account.
          </p>
        </section>

        <section className="sectionText">
          <h5 className="title">4. Use of the Platform</h5>
          <p className="detail">
            <b>4.1 Event Organizers:</b> Event Organizers are responsible for
            creating, managing, and promoting their events. Frondesq does not
            control or endorse any events or Event Organizers and is not
            responsible for the content, quality, or conduct of any event.
          </p>

          <p className="detail">
            <b>4.2 Attendees:</b> Attendees are responsible for purchasing
            tickets and complying with the terms and conditions set by Event
            Organizers. Frondesq is not responsible for any issues related to
            event attendance, including cancellations or changes to events.
          </p>

          <p className="detail">
            <b>4.3 Prohibited Activities:</b> You agree not to engage in any of
            the following prohibited activities:
            <ul>
              <li>Violating any applicable laws or regulations.</li>
              <li>
                Impersonating any person or entity or falsely stating or
                otherwise misrepresenting your affiliation with a person or
                entity.
              </li>
              <li>
                Interfering with or disrupting the operation of our Platform.
              </li>
              <li>
                Using any automated means or manual processes to collect
                information from our Platform.
              </li>
              <li>Engaging in any fraudulent or deceptive practices.</li>
            </ul>
          </p>
        </section>

        <section className="sectionText">
          <h5 className="title">5. Ticket Purchases and Fees</h5>
          <p className="detail">
            <b>5.1 Ticket Purchases:</b> All ticket purchases are subject to the
            terms and conditions set by the Event Organizer. Frondesq is not
            responsible for the refund or cancellation policies of Event
            Organizers.
          </p>

          <p className="detail">
            <b>5.2 Fees:</b> Frondesq may charge fees for the use of our
            Platform, including a processing fee for each ticket purchase
            ("Frondesq Fee"). You will be informed of any applicable fees at the
            time of purchase.
          </p>
        </section>

        <section className="sectionText">
          <h5 className="title">6. Refunds and Cancellations</h5>
          <p className="detail">
            All refund and cancellation requests must be directed to the Event
            Organizer. Frondesq is not responsible for processing refunds or
            handling cancellation requests. The Frondesq Fee is non-refundable.
          </p>
        </section>

        <section className="sectionText">
          <h5 className="title">7. Intellectual Property</h5>
          <p className="detail">
            <b>7.1 Ownership:</b> All content and materials on our Platform,
            including text, graphics, logos, and software, are the property of
            Frondesq or its licensors and are protected by copyright, trademark,
            and other intellectual property laws.
          </p>
          <p className="detail">
            <b>7.2 License:</b> Frondesq grants you a limited, non-exclusive,
            non-transferable license to access and use our Platform for personal
            and non-commercial purposes. You may not reproduce, distribute,
            modify, or create derivative works from our content without our
            prior written consent.
          </p>
        </section>

        <section className="sectionText">
          <h5 className="title">8. Privacy</h5>
          <p className="detail">
            Our Privacy Policy explains how we collect, use, and protect your
            personal information. By using our Platform, you consent to the
            collection and use of your information as described in our Privacy
            Policy.
          </p>
        </section>

        <section className="sectionText">
          <h5 className="title">9. Limitation of Liability</h5>
          <p className="detail">
            Frondesq will not be liable for any direct, indirect, incidental,
            consequential, special, punitive, or exemplary damages arising from
            your use of our Platform or any events facilitated through our
            Platform.
          </p>
        </section>

        <section className="sectionText">
          <h5 className="title">10. Indemnification</h5>
          <p className="detail">
            You agree to indemnify, defend, and hold harmless Frondesq, its
            affiliates, and their respective officers, directors, employees, and
            agents from and against any claims, liabilities, damages, losses,
            and expenses arising out of or in connection with your use of our
            Platform or your violation of these Terms.
          </p>
        </section>

        <section className="sectionText">
          <h5 className="title">11. Changes to Terms</h5>
          <p className="detail">
            We may modify these Terms at any time by posting the revised Terms
            on our Platform. Your continued use of our Platform after the
            effective date of the revised Terms constitutes your acceptance of
            the changes.
          </p>
        </section>

        <section className="sectionText">
          <h5 className="title">12. Governing Law</h5>
          <p className="detail">
            These Terms are governed by and construed in accordance with the
            laws of Nigeria. Any disputes arising under or in connection with
            these Terms shall be subject to the exclusive jurisdiction of the
            courts of Nigeria.
          </p>
        </section>

        <section className="sectionText">
          <h5 className="title">13. Contact Us</h5>
          <p className="detail">
            If you have any questions or concerns about these Terms, please
            contact us at:
          </p>
          <p className="detail">
            <strong>Frondesq</strong>
            <br />
            OneID Global Technologies Ltd <br />
            Lagoon view estate, Makoko Rd, Lagos. <br />
            <b>Email:</b> hello@oneidtech.com <br />
            <b>Phone:</b> 09078899843
          </p>
          <p className="detail">
            By using our Platform, you acknowledge that you have read and
            understood these Terms and agree to be bound by them.
          </p>
        </section>
      </ComplianceBox>
      <Footer />
    </React.Fragment>
  )
}
