import { styled } from "@mui/material";

export const GetStartedBox = styled("section")(({ theme }) => ({
  padding: "3rem 0",

  "& .contentContainer": {
    borderRadius: "8px",
    border: `1px solid ${theme.palette.secondary.main}`,
    padding: "3rem",
    margin: "0 4rem",

    "& .title": {
      textAlign: "center",
      fontSize: "3.5rem",
      fontWeight: 600,
      color: theme.palette.secondary.main,
      marginBottom: "3.5rem",
    },
  },

  "& .vendorFlowContainer": {
    textAlign: "center",
    padding: "0 2rem",

    "& .header": {
      fontSize: "2.5rem",
      fontWeight: 600,
      color: theme.palette.primary.main,
      marginTop: "3.5rem",
    },

    "& .details": {
      fontSize: "1rem",
      fontWeight: 400,
      color: "#424242",
      width: "70%",
      margin: "0 auto 1.5rem",
    },
  },

  "& .navBar": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "& .MuiButtonBase-root": {
      width: "60px",
      height: "60px",
      borderRadius: "50%",
      border: `1px solid ${theme.palette.secondary.main}`,
    },
  },

  "@media screen and (max-width: 1200px)": {
    "& .contentContainer": {
      padding: "2rem",
      margin: "0 2rem",

      "& .title": {
        fontSize: "3rem",
        marginBottom: "2.75rem",
      },
    },

    "& .vendorFlowContainer": {
      padding: "0 1.5rem",

      "& .header": {
        fontSize: "2rem",
        marginTop: "2.75rem",
      },

      "& .details": {
        fontSize: "1rem",
        width: "90%",
        margin: "0 auto 1.5rem",
      },
    },

    "& .navBar": {
      "& .MuiButtonBase-root": {
        width: "40px",
        height: "40px",
      },
    },
  },

  "@media screen and (max-width: 991.9px)": {
    "& .contentContainer": {
      padding: "2rem",
      margin: "0",

      "& .title": {
        fontSize: "2.75rem",
        marginBottom: "2.25rem",
      },
    },

    "& .vendorFlowContainer": {
      padding: "0 1.5rem",

      "& .header": {
        fontSize: "1.75rem",
        marginTop: "2.25rem",
      },

      "& .details": {
        fontSize: "0.95rem",
        width: "100%",
        margin: "0 auto 1rem",
      },
    },

    "& .navBar": {
      "& .MuiButtonBase-root": {
        width: "40px",
        height: "40px",
      },
    },
  },

  "@media screen and (max-width: 767.9px)": {
    "& .contentContainer": {
      padding: "1.5rem",

      "& .title": {
        fontSize: "2.25rem",
        marginBottom: "2rem",
      },
    },

    "& .vendorFlowContainer": {
      padding: "0 1rem",

      "& .header": {
        fontSize: "1.65rem",
        marginTop: "2rem",
      },

      "& .details": {
        fontSize: "0.9rem",
      },
    },

    "& .navBar": {
      "& .MuiButtonBase-root": {
        width: "35px",
        height: "35px",
      },
    },
  },
}));
