import { styled } from "@mui/material";

export const ComingSoonBox = styled("section")(({ theme }) => ({
  width: "100%",
  minHeight: "80vh",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "#ffffff",

  "& .textContainer": {
    width: "380px",
    textAlign: "center",

    "& .details": {
      color: theme.palette.secondary.main,
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "24px",
      margin: "2.5rem 0",
    },

    "@media (max-width: 991.9px": {
      width: "500px",
    },

    "@media (max-width: 767.9px": {
      width: "85%",
    },

    "@media (max-width: 567.9px": {
      "& .details": {
        fontSize: "0.875rem",
        margin: "2rem 0",
      },
    },
  },
}));
