import * as React from "react";
import { HeaderBox } from "./header.style";
import NotificationIcon from "../../../assets/images/icon/Notification.svg";
import Avatar from "../../../assets/icon/Avatars.svg";
import ArrowDown from "../../../assets/images/icon/arrow-down.svg";
import { Menu, MenuItem } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { LogoTextLightBg, PopoverComponent, DropDownForPopOver } from "../..";
import { IUserDetails } from "../../../types";
import {
  LogOutIcon,
  HelpCenterIcon,
  AccountUpgradeIcon,
  ProfileSettingIcon,
  ProfileIcon,
} from "../../../assets/svg";
import { SETTINGS_URL } from "../../../helpers/routes.url";
import { useQueryClient } from "@tanstack/react-query";
import { useResetPermissions } from "../../../context";

interface IProps {
  handleToggle: () => void;
  userDetails: IUserDetails | undefined;
}

type TMenuType = "profile" | "manage" | "upgrade" | "help" | "logout";

interface IMenu {
  name: string;
  value: TMenuType;
  Icon: () => JSX.Element;
  link: string;
}

export const Header: React.FC<IProps> = ({ handleToggle, userDetails }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const queryClient = useQueryClient();
  const resetPermissions = useResetPermissions();

  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const onLogout = () => {
    // resetPermissions();
    queryClient.clear();
    localStorage.removeItem("FD_AU_TK");
    localStorage.removeItem("FD_AU_TK_R");
    localStorage.clear();
    window.location.href = `${window.location.origin}/sign-in`;
    // navigate("/sign-in", { replace: true });
  }; 

  const handleMenuRoute = (route: string, menu_value: TMenuType) => {
    switch (menu_value) {
      case "profile":
        navigate(route, {
          state: { tab: 0 },
        });
        break;
      case "manage":
        navigate(route, {
          state: { tab: 1 },
        });
        break;
      case "upgrade":
        navigate(route, {
          state: { tab: 2 },
        });
        break;
      case "help":
        break;
      case "logout":
        onLogout();
        break;

      default:
        break;
    }
  };

  const avatarMenus: IMenu[] = [
    {
      name: "Profile",
      value: "profile",
      Icon: ProfileIcon,
      link: SETTINGS_URL,
    },
    {
      name: "Manage Admins",
      value: "manage",
      Icon: ProfileSettingIcon,
      link: SETTINGS_URL,
    },
    {
      name: "Account Upgrade",
      value: "upgrade",
      Icon: AccountUpgradeIcon,
      link: SETTINGS_URL,
    },
    {
      name: "Help Center",
      value: "help",
      Icon: HelpCenterIcon,
      link: "",
    },
    {
      name: "Logout",
      value: "logout",
      Icon: LogOutIcon,
      link: "",
    },
  ];

  return (
    <HeaderBox>
      <IconButton
        className="d-inline-block d-lg-none pointer hamburgerMenu"
        onClick={handleToggle}
      >
        <svg
          width="30"
          height="25"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 6.66699H35V10.0003H5V6.66699ZM5 18.3337H35V21.667H5V18.3337ZM5 30.0003H35V33.3337H5V30.0003Z"
            fill="#001935"
          />
        </svg>
      </IconButton>

      <div className="searchContainer d-none d-lg-block">
        <svg
          className="searchIcon"
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 0C13.968 0 18 4.032 18 9C18 13.968 13.968 18 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0ZM9 16C12.8675 16 16 12.8675 16 9C16 5.1325 12.8675 2 9 2C5.1325 2 2 5.1325 2 9C2 12.8675 5.1325 16 9 16ZM17.4853 16.0711L20.3137 18.8995L18.8995 20.3137L16.0711 17.4853L17.4853 16.0711Z"
            fill="#939393"
          />
        </svg>

        <input
          type="text"
          placeholder="Search by name, appointment or event"
          className="form-control"
        />
      </div>

      <div className="mx-auto d-block d-lg-none">
        <LogoTextLightBg width="135" />
      </div>

      <div className="sidepan">
        <div className="d-none d-lg-inline-flex greetings bR">
          Upgrade
          {/* Hello, <div className="companyName text-truncate">ABC Company</div> */}
        </div>

        <div className="d-none d-lg-inline-flex adminBadge bR">
          {userDetails?.role?.toLowerCase()}
        </div>

        <div className="notificationContainer">
          <svg
            className="dot"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="4" cy="4" r="4" fill="#F71C1C" />
          </svg>

          <svg
            width="18"
            height="22"
            viewBox="0 0 18 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 8C15 4.68629 12.3137 2 9 2C5.68629 2 3 4.68629 3 8V16H15V8ZM17 16.6667L17.4 17.2C17.5657 17.4209 17.5209 17.7343 17.3 17.9C17.2135 17.9649 17.1082 18 17 18H1C0.72386 18 0.5 17.7761 0.5 17.5C0.5 17.3918 0.53509 17.2865 0.6 17.2L1 16.6667V8C1 3.58172 4.58172 0 9 0C13.4183 0 17 3.58172 17 8V16.6667ZM6.5 19H11.5C11.5 20.3807 10.3807 21.5 9 21.5C7.6193 21.5 6.5 20.3807 6.5 19Z"
              fill="#001935"
            />
          </svg>
        </div>

        <PopoverComponent
          title={
            <img src={Avatar} alt="avatar" className="avatarImg ms-auto" />
          }
          children={
            <DropDownForPopOver>
              {avatarMenus.map(
                ({ name, value, Icon, link }: IMenu, index: number) => (
                  <div
                    key={`${index}-${value}`}
                    className={`item ${value === "logout" ? "dangerText" : ""}`}
                    onClick={() => {
                      handleMenuRoute(link, value);
                    }}
                  >
                    <>
                      <Icon />
                      <span className="ms-2">{name}</span>
                    </>
                  </div>
                )
              )}
            </DropDownForPopOver>
          }
        />
      </div>
    </HeaderBox>
  );
};
