import { styled } from "@mui/material"

export const FooterBox = styled("footer")(({ theme }) => ({
  padding: "2.5rem 0",
  backgroundColor: "#F5F5F5",

  "& .contentHolder": {
    display: "flex",
    // alignItems: "center",
    justifyContent: "space-between",
  },

  "& .footerNoteHolder": {
    display: "flex",
    // gap: "0 2rem",
    listStyle: "none",
    padding: 0,
    margin: "1rem 0 0",

    "& li": {
      fontSize: "0.75rem",
      fontWeight: 600,
      color: theme.palette.primary.main,

      "& a": {
        cursor: "pointer",
        textDecoration: "none",
      },

      "&:first-child": {
        color: "#787878",
      },

      "&:not(:last-child)::after": {
        content: "'|'",
        margin: "0 0.5rem",
        color: "#787878",
      },
    },
  },

  "& .socialIconHolder": {
    display: "flex",
    gap: "0 1.25rem",
    alignItems: "center",

    "& a": {
      cursor: "pointer",
      textDecoration: "none",
    },
  },

  "@media screen and (max-width: 1199.9px)": {},

  "@media screen and (max-width: 767.9px)": {
    padding: "4rem 0",

    "& .contentHolder": {
      alignItems: "flex-start",
      flexWrap: "wrap",
      gap: "1.25rem 0",
      //   justifyContent: "center",
    },

    "& .footerNoteHolder": {
      margin: "1rem 0 0",

      "& li": {
        fontSize: "0.65rem",

        "&:not(:last-child)::after": {
          margin: "0 0.3rem",
        },
      },
    },

    "& .socialIconHolder": {
      gap: "0 1rem",

      "& a": {
        "& img": {
          width: "15px",
          height: "15px",
        },
      },
    },
  },

  "@media screen and (max-width: 399.9px)": {
    "& .contentHolder": {
      justifyContent: "center",
    },
  },
}))
