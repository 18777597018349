import { FDPButton } from "..";
import Lottie from "react-lottie-player";
import { NoPermissionBox } from "./noPermission.styles";
import NoAccessLottie from "../../../assets/lottie/no-access.json";
import React from "react";

interface IProps {
  handleClose: () => void;
}

export const NoPermission: React.FC<IProps> = ({ handleClose }) => {
  return (
    <NoPermissionBox>
      <div className="textContainer">
        <h3 className="title">Oops!</h3>
        <Lottie
          className="mx-auto d-block mb-3"
          play
          loop={1000} // providing 1 makes it loop 2x
          style={{
            width: "300px",
            height: "100px",
          }}
          animationData={NoAccessLottie}
        />
        <p className="mb-4">
          It looks like you don't have permission to access this page or
          feature. To get the access you need, please reach out to the account
          administrator.
        </p>
        <FDPButton text="Close" btnVariant="Primary" click={handleClose} />
      </div>
    </NoPermissionBox>
  );
};
