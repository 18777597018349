import { styled } from "@mui/material";

export const SelfCheckInBox = styled("section")(({ theme }) => ({
    // padding: "0 1.25rem",
    color: "#0C0C0C",
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",

    "& .main": {
        minHeight: "calc(100vh - 180px)",
    },

    "& .wrapper": {
        width: "full",
        maxWidth: "1010px",
        margin: "20px auto 60px auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1rem",
    },

    "& .cardWrap": {
        width: "100%",
        border: "1px solid #E0E0E0",
        borderRadius: "8px",
        padding: "2rem 0.5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1rem",
    },

    "& .imgHolder": {
        width: "100%",
        height: "400px",
        borderRadius: "8px",
        overflow: "hidden",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "cover",

        "& img": {
            width: "100%",
            height: "100%",
            objectPosition: "center",
            objectFit: "cover",
        },
    },

    "& .title": {
        fontSize: "2.5rem",
        textAlign: "center",
        fontWeight: "600",
    },

    "& .usefulInfo": {
        display: "flex",
        flexWrap: "wrap",
        gap: "0.5rem",
        alignItems: "center",
        justifyContent: "center",

        "& .eventType": {
            padding: "0.5rem 1.25rem",
            borderRadius: "100px",
            backgroundColor: "#F5F5F5",
            color: "#0C0C0C",
            fontSize: "14px",
        },

        "& .virtualOrPhysical": {
            padding: "0.5rem 1.25rem",
            borderRadius: "100px",
            backgroundColor: "#FAE3A5",
            color: "#0C0C0C",
            fontSize: "14px",
        },

        "& .dateFromNow": {
            padding: "0.5rem 1.25rem",
            borderRadius: "100px",
            backgroundColor: "#CEF7F7",
            color: "#0C0C0C",
            fontSize: "14px",
        },
    },

    "& .dateAndTime": {
        display: "flex",
        flexWrap: "wrap",
        gap: "1rem",
        alignItems: "center",
        justifyContent: "center",

        "& .date": {
            display: "flex",
            alignItems: "flex-end",
            gap: "0.25rem",
            color: "#0B61DD",
            fontSize: "16px",
            fontWeight: "500",
        },

        "& .location": {
            display: "flex",
            alignItems: "center",
            gap: "0.25rem",
            color: "#0B61DD",
            fontSize: "16px",
            fontWeight: "500",
        },
    },

    "& .aboutWrap": {
        width: "100%",
        border: "1px solid #E0E0E0",
        borderRadius: "8px",
        padding: "2rem 1rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",

        "& .aboutTitle": {
            fontSize: "1.25rem",
        },
        "& .details": {
            color: "#424242",
            // paddingBottom: "7rem",
        },
        "& p": {
            marginBottom: 0,
        },
    },


    [theme.breakpoints.down("md")]: {
        "& .imgHolder": {
            height: "200px",
        },

        "& .title": {
            fontSize: "1.5rem",
            textAlign: "start",
        },

        "& .usefulInfo": {
            justifyContent: "flex-start",
        },

        "& .dateAndTime": {
            justifyContent: "flex-start",
        },
    },




    "@media screen and (max-width: 767.9px)": {
        '& .main': {
            minHeight: "calc(100vh - 287px)"
        },

        "& .imgHolder": {
            height: "170px",
        },

        "& .title": {
            fontSize: "1.25rem",
        },

        "& .dateAndTime": {
            justifyContent: "flex-start",
            gap: "0.75rem",

            "& .date, .location": {
                fontSize: "0.875rem",
                gap: "0.25rem",
                alignItems: "center",
            },

            "& .date svg, .location svg": {
                minWidth: "15px",
                minHeight: "15px",
                width: "15px",
                height: "15px",
            },
        },

        "& .usefulInfo": {
            width: "100%",
            justifyContent: "flex-start",

            "& .eventType, .virtualOrPhysical, .dateFromNow": {
                fontSize: "0.75rem",
                padding: "0.25rem 1rem",
                fontWeight: 500,
            },
        },

        "& .cardWrap, .aboutWrap": {
            padding: "1.5rem 1rem",
        },


        "& .aboutWrap": {
            "& .aboutTitle": {
                fontSize: "1rem",
            },

            "& .details": {
                fontSize: "0.875rem",
            },
        },
    },
}));
