import { styled } from "@mui/material";

export const NoPermissionBox = styled("section")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "#ffffff",

  "& .textContainer": {
    textAlign: "center",
    padding: "0 1rem 2.5rem",

    "& .title": {
      color: theme.palette.secondary.main,
      fontSize: "1.5rem",
      fontWeight: 600,
    },

    "& .details": {
      color: "#424242",
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "24px",
      margin: "2.5rem 0",
    },

    "@media (max-width: 991.9px": {
      width: "500px",
    },

    "@media (max-width: 767.9px": {
      width: "85%",
    },

    "@media (max-width: 567.9px": {
      "& .details": {
        fontSize: "0.875rem",
        margin: "2rem 0",
      },
    },
  },
}));
