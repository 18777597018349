// export const PERMISSION_CHECK_IN = "CHECKIN_PARTICIPANT";
// export const PERMISSION_EXPORT_PARTICIPANT = "EXPORT_PARTICIPANT_LIST";
// export const PERMISSION_VIEW_PARTICIPANT = "READ_PARTICIPANT_DETAILS";
// export const PERMISSION_ADD_ADMIN = "CREATE_ADMIN";
// export const PERMISSION_UPDATE_ADMIN_PASSWORD = "UPDATE_PASSWORD";
// export const PERMISSION_MANAGE_SETTING = "MANAGE_SETTING";
export enum PERMISSION_LISTS {
  CHECKIN_PARTICIPANT = "CHECKIN_PARTICIPANT",
  READ_PARTICIPANT_DETAILS = "READ_PARTICIPANT_DETAILS",
  EXPORT_PARTICIPANT_LIST = "EXPORT_PARTICIPANT_LIST",
  UPDATE_PASSWORD = "UPDATE_PASSWORD",
  CREATE_ADMIN = "CREATE_ADMIN",
  MANAGE_DISCOUNT = "MANAGE_DISCOUNT",
  DELETE_DISCOUNT = "DELETE_DISCOUNT",
  CREATE_DISCOUNT = "CREATE_DISCOUNT",
  MANAGE_SETTING = "MANAGE_SETTING",
  MANAGE_ALL = "MANAGE_ALL",
}
