import { NavBox } from "./nav.styles";
import { LogoTextLightBg, FDPButton } from "../../../../components";
import { useNavigate } from "react-router-dom";
import {
  SIGN_IN_URL,
  EVENTS_URL,
  EVENT_MANAGE_URL,
} from "../../../../helpers/routes.url";

export const Navbar = ({ eventId }: any) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("FD_AU_TK");
  const isLogin = !!(token && token !== "");
  const btnStyle = {
    padding: "1rem 1.5rem",
    border: "2px solid",
  };

  return (
    <NavBox>
      <div className="container-fluid">
        <div className="navHolder">
          <div className="logoContainer" onClick={() => navigate("/")}>
            <LogoTextLightBg />
          </div>

          <div className="ms-auto">
            {!isLogin ? (
              <FDPButton
                buttonProps={{
                  sx: btnStyle,
                }}
                btnVariant="PrimaryOutline"
                text="Log in"
                click={() => navigate(SIGN_IN_URL)}
              />
            ) : (
              <div className="d-flex gap-2 gap-lg-3">
                <FDPButton
                  buttonProps={{
                    sx: btnStyle,
                  }}
                  btnVariant="PrimaryOutline"
                  text="All Events"
                  click={() => navigate(EVENTS_URL)}
                />
                <FDPButton
                  buttonProps={{
                    sx: btnStyle,
                  }}
                  btnVariant="PrimaryOutline"
                  text="Manage Event"
                  click={() => navigate(`${EVENT_MANAGE_URL}/${eventId}`)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </NavBox>
  );
};
