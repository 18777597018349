export function urlParamsToObject(urlParams: URLSearchParams) {
  const entries = urlParams.entries()
  const result: { [key: string]: string | null } = {}

  for (const [key, value] of entries) {
    let newValue

    switch (value) {
      case "undefined":
        continue
      case "null":
        newValue = null
        break
      default:
        newValue = value
        break
    }

    result[key] = newValue
  }

  return result
}

export function objectToUrlSearchParams(obj: Record<string, any>): string {
  const searchParams = new URLSearchParams()

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      searchParams.append(key, obj[key].toString())
    }
  }

  return searchParams.toString()
}
