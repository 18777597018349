import axiosInstance from "./instance";

// Payment

export const verifyPayment = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: `paystack/verify-transaction/${data.queryKey[1].reference}`,
      method: "GET",
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
