import axios from "axios";
import { Config } from "../config";
import jwtDecode from "jwt-decode";
import { IJwtDetails } from "../types";

const accessToken = localStorage.getItem("FD_AU_TK");
const refreshToken = localStorage.getItem("FD_AU_TK_R");

export const getDecodedToken = () => {
  const accessToken = localStorage.getItem("FD_AU_TK");

  const decodedToken: IJwtDetails = jwtDecode(accessToken as string);
  return decodedToken;
};

const axiosInstance = axios.create({
  baseURL: Config.API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add an interceptor to handle token refresh
if (accessToken) {
  let isRefreshing = false;
  let failedRequests: any[] = [];

  // Pass token for calls
  axiosInstance.interceptors.request.use(
    (config: any) => {
      // Check if the API URL requires a bearer token
      if (config.url.includes("auth/") || config.url.includes("uploader/")) {
        // No token is required for authentication endpoints
        return config;
      }

      // Add the bearer token to other API requests
      const accessToken = localStorage.getItem("FD_AU_TK");
      if (accessToken || config.url.includes("auth/current-user")) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Handling refresh token
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (
        error.response?.status === 401 &&
        !originalRequest._retry &&
        originalRequest?.url.includes("auth/login") === false
      ) {
        if (!isRefreshing) {
          isRefreshing = true;

          // Make a request to refresh the access token using the refresh token
          try {
            const refreshResponse = await axiosInstance({
              url: "auth/refresh-token",
              method: "POST",
              data: {
                refreshToken: refreshToken,
              },
            });

            // Update the access token with the new one
            const newAccessToken = refreshResponse.data.access_token;
            localStorage.setItem("FD_AU_TK", newAccessToken);

            // Retry the original request with the new access token
            axiosInstance.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${newAccessToken}`;
            originalRequest._retry = true;

            // Resolve all the pending requests that were waiting for token refresh
            failedRequests.forEach((req) => req.resolve(newAccessToken));

            // Clear the failed requests array
            failedRequests = [];

            return axiosInstance(originalRequest);
          } catch (refreshError) {
            return Promise.reject(refreshError);
          } finally {
            console.log("Got here ");
            isRefreshing = false;
          }
        }
        // else if (){

        // }
        else {
          // If another request is refreshing the token, wait and retry the original request later
          return new Promise((resolve, reject) => {
            failedRequests.push({ resolve, reject, config: originalRequest });
          });
        }
      }

      return Promise.reject(error);
    }
  );
}

export default axiosInstance;
