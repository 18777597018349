import { CircularProgress, styled } from "@mui/material"

const LoadingBox = styled("div")((theme) => ({
  height: "70vh",
  minHeight: "20vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

export const Loading = () => {
  return (
    <LoadingBox>
      <CircularProgress />
    </LoadingBox>
  )
}
