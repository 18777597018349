import { styled } from "@mui/material";

export const ContactBox = styled("section")(({ theme }) => ({
  margin: "5rem 0",

  "& .contentHolder": {
    backgroundColor: "#CEF7F7",
    borderRadius: "8px",
    padding: "3.5rem 5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "3rem 5rem",
    fleWrap: "wrap",
  },

  "& .textHolder": {
    "& .title": {
      fontSize: "2.5rem",
      color: theme.palette.secondary.main,
      fontWeight: 600,
      marginBottom: "1rem",
    },

    "& .contactInfo": {
      display: "flex",
      alignItems: "center",
      gap: "0 1.25rem",

      "& .item": {
        "& img": {
          marginRight: "0.5rem",
        },

        "& a": {
          fontSize: "1.25rem",
          fontWeight: 400,
          color: theme.palette.secondary.main,
          textDecoration: "none",
        },
      },
    },
  },

  "@media screen and (max-width: 1199.9px)": {
    "& .textHolder": {
      "& .title": {
        fontSize: "2rem",
      },

      "& .contactInfo": {
        "& .item": {
          "& a": {
            fontSize: "1rem",
          },
        },
      },
    },
  },

  "@media screen and (max-width: 767.9px)": {
    "& .contentHolder": {
      padding: "3.5rem 5rem",
      flexDirection: "column",
      gap: "3rem 5rem",
      textAlign: "center",
    },

    "& .textHolder": {
      "& .title": {
        fontSize: "1.85rem",
        marginBottom: "0.75rem",
      },

      "& .contactInfo": {
        gap: "0 2.5rem",
        justifyContent: "center",

        "& .item": {
          "& a": {
            fontSize: "0.9rem",
          },
        },
      },
    },
  },
}));
