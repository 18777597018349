import { ContactBox } from "./Contact.styles";
import MailIcon from "../../../../assets/icon/mail-fill.svg";
import PhoneIcon from "../../../../assets/icon/phone-fill.svg";
import ContactLottie from "../../../../assets/lottie/contact-us.json";
import Lottie from "react-lottie-player";

export const Contact = () => {
  return (
    <ContactBox id="contactSection">
      <div className="container-fluid">
        <div className="contentHolder">
          <Lottie
            className="d-inline-block"
            play
            loop
            style={{
              width: "240px",
              height: "140px",
            }}
            animationData={ContactLottie}
          />

          <div className="textHolder">
            <h3 className="title">Got any questions about FronDesq?</h3>
            <div className="contactInfo">
              <div className="item">
                <img src={PhoneIcon} alt="Phone icon" />
                <a href="tel:+2349078899843">09078899843</a>
              </div>
              <div className="item">
                <img src={MailIcon} alt="Phone icon" />
                <a href="mailto:hello@oneidtech.com">hello@oneidtech.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContactBox>
  );
};
