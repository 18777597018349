import { NavigatorBox } from "./navigator.style";
import { DrawerProps } from "@mui/material";
import { Drawer, List, ListItem, Collapse } from "@mui/material";
import { navigatorData, INavigator, INavChildren } from "../../../helpers";
import { useLocation } from "react-router-dom";
import { LogoTextLightBg } from "../../lib/Logo";
import { useNavigate } from "react-router-dom";
import Slide from "@mui/material/Slide";
import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { ChervonArrowDownIcon } from "../../../assets/svg";

interface Props {
  open: boolean;
  drawerWidth: string | number;
  onOutsideClick: () => void;
  windowWidth: number;
}

export const Navigator: React.FC<Props> = ({
  drawerWidth,
  open,
  onOutsideClick,
  windowWidth,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const targetRef = React.useRef<any>(null);
  const [collapseValue, setCollapseValue] = React.useState<number | null>(null);

  React.useEffect(() => {
    const handleDocumentClick = (event: any) => {
      if (windowWidth <= theme.breakpoints.values.lg) handleClickOutside(event);
    };

    document.addEventListener("mousedown", handleDocumentClick);

    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (targetRef.current && !targetRef.current?.contains(event.target)) {
      // Clicked outside the target element
      onOutsideClick();
    }
  };

  const handleOpenCollapse = (index: number) => {
    if (index === collapseValue) {
      setCollapseValue(null);
      return;
    }
    setCollapseValue(index);
  };

  const handleRouting = (link: string) => {
    setCollapseValue(null);
    navigate(link);
  };

  const renderChildrenSingleModule = (
    title: string,
    link: string | undefined,
    icon: any | null
  ) => {
    return (
      <div
        onClick={() => {
          navigate(link as string);
          // onOutsideClick();
        }}
        className={`${
          location.pathname === link ? "singleActiveItem" : ""
        } singleItemTitleList `}
      >
        {icon ? <div className="icon">{icon}</div> : <> &nbsp; </>}
        <div>{title}</div>
      </div>
    );
  };

  const renderMenuwithIcon = (
    index: number,
    title: string,
    icon: any,
    link: string | undefined,
    children: any[] | null
  ) => {
    return (
      <div
        onClick={() => {
          children ? handleOpenCollapse(index) : handleRouting(link as string);
          // onOutsideClick();
        }}
        className={`${
          location.pathname === link || index === collapseValue
            ? "activeLink"
            : ""
        } itemList`}
      >
        {icon && <div className="icon">{icon}</div>}
        <div>{title}</div>
        {children && (
          <div className="chervonDown ms-auto">
            <ChervonArrowDownIcon />
          </div>
        )}
      </div>
    );
  };

  return (
    <NavigatorBox
      sx={{
        width: `${open ? `${drawerWidth}px` : "0px"}`,
      }}
      ref={targetRef}
    >
      <Slide direction="right" in={open} mountOnEnter unmountOnExit>
        <div className="navBg">
          <div className="logoSection">
            <LogoTextLightBg />
          </div>

          <List className="listContainer">
            {navigatorData.map((item: INavigator, index) => {
              return (
                <ListItem key={`${index}-${item.title}`} disablePadding>
                  <div className="w-100">
                    {renderMenuwithIcon(
                      index,
                      item.title,
                      item.icon,
                      item.link as string,
                      item.children
                    )}
                    <Collapse
                      in={collapseValue === index ? true : false}
                      timeout="auto"
                      unmountOnExit
                    >
                      {item?.children?.length >= 1 && (
                        <>
                          {item.children.map(
                            (
                              { subTitle, icon, link }: INavChildren,
                              index: number
                            ) => (
                              <div key={`${index}-${subTitle}`}>
                                {renderChildrenSingleModule(
                                  subTitle,
                                  link,
                                  icon
                                )}
                              </div>
                            )
                          )}
                        </>
                      )}
                    </Collapse>

                    {/* Menu with chldren, when Multiple modules are selected */}

                    {/* {item?.children?.length >= 1 && (
                      <>
                        <hr className="divider" />
                        {item.children.map(
                          ({ subTitle, icon, link }: INavChildren) => (
                            <div className="mb-2">
                              {renderMenuwithIcon(subTitle, icon, link)}
                            </div>
                          )
                        )}
                      </>
                    )} */}
                  </div>
                </ListItem>
              );
            })}
          </List>
        </div>
      </Slide>
    </NavigatorBox>
  );
};
