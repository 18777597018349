import { styled } from "@mui/material";
import { fontWeight } from "@mui/system";

export const HeaderBox = styled("header")(({ theme }) => ({
  height: "75px",
  borderBottom: "1px solid #E4E4E4",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 1.5rem",
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  WebkitBackdropFilter: "blur(5px)",
  backdropFilter: "blur(5px)",

  [theme.breakpoints.up("lg")]: {
    marginLeft: theme.drawerWidth,
  },

  [theme.breakpoints.down("xs")]: {
    padding: "2rem 1rem",
  },

  [theme.breakpoints.down("lg")]: {
    position: "relative",
    zIndex: 500,
  },

  "& .searchContainer": {
    position: "relative",

    "& .searchIcon": {
      position: "absolute",
      left: "1rem",
      top: "0.9rem",
    },

    "& input": {
      background: "#FAFAFA",
      padding: "0.9rem 1rem 0.9rem 3rem",
      border: "1px solid #E4E4E4",
      borderRadius: "8px",
      width: "350px",

      "&:focus": {
        outline: "none",
        boxShadow: "none",
        border: "1px solid #0B61DD",
      },

      "&::placeholder": {
        fontSize: "0.875rem",
        fontWeight: 600,
        color: "#939393",
      },
    },
  },

  "& .notificationContainer": {
    position: "relative",

    "& .dot": {
      position: "absolute",
      right: 0,
      top: 0,
    },
  },

  "& .avatarImg": {
    width: "35px",
    height: "35px",
    borderRadius: "50%",
  },

  "& .greetings": {
    display: "inline-flex",
    alignItems: "center",
    color: theme.palette.secondary.main,
    background: "#E4E4E4",

    "& .companyName": {
      width: "80px",
    },
  },

  "& .adminBadge": {
    color: theme.palette.secondary.main,
    background: "#9CEFEF",
    textTransform: "capitalize !important",
  },

  "& .bR": {
    padding: "0.4rem 0.8rem",
    borderRadius: "100px",
  },

  "& .sidepan": {
    display: "flex",
    alignItems: "center",
    gap: "0 1.25rem",
    fontSize: "0.875rem",
    fontWeight: 600,
  },

  "@media screen and (max-width: 767.9px)": {
    padding: "0 1rem",

    "& .hamburgerMenu": {
      paddingLeft: "0",

      "& svg": {
        width: "20px",
      },
    },

    "& .notificationContainer": {
      "& svg": {
        display: "none", // Make visible later
        width: "15px",
      },
    },

    "& .avatarImg": {
      width: "25px",
      height: "25px",
    },
  },
}));
