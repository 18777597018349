import { styled } from "@mui/material";

export const NavBox = styled("nav")(({ theme }) => ({
  padding: "1.5rem 0",
  borderBottom: "1px solid #E4E4E4",

  "& .navHolder": {
    display: "flex",
    alignItems: "center",
  },

  "@media screen and (max-width: 1199.9px)": {
    padding: "1rem 0",
    borderBottom: "1px solid #E4E4E4",

    "& .logoContainer": {
      "& img": {
        width: "115px !important",
      },
    },

    "& button": {
      fontSize: "0.75rem",
      padding: "0.55rem 0.75rem",
      height: "40px",
    },
  },
}));
