import { MainRoutes } from "./routes/main-routes";
import { ThemeProvider } from "./components";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import PermissionProvider from "./context/PermissionProvider/PermissionProvider";

function App() {
  return (
    <ThemeProvider>
      <PermissionProvider>
        <MainRoutes />
        {/* Toast Container - Allow toast to show across the app */}
        <ToastContainer />
      </PermissionProvider>
    </ThemeProvider> 
  );
} 

export default App;
