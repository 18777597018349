import { styled } from "@mui/material";

export const EventNotFoundBox = styled("section")(({ theme }) => ({
  width: "100%",
  minHeight: "calc(100vh - 170px)",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "#ffffff",

  "& .textHolder": {
    width: "500px",
    textAlign: "center",

    "& .title": {
      fontSize: "4rem",
      fontWeight: 600,
      marginBottom: "1rem",
    },

    "& .details": {
      color: "#424242",
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "24px",
      margin: "0.5rem 0 1.5rem",
    },

    "@media (max-width: 991.9px": {
      width: "500px",

      "& .title": {
        fontSize: "3.75rem",
      },
    },

    "@media (max-width: 767.9px": {
      width: "85%",

      "& .title": {
        fontSize: "3rem",
      },
    },

    "@media (max-width: 567.9px": {
      "& .title": {
        fontSize: "2.5rem !important",
      },

      "& .details": {
        fontSize: "0.875rem",
        margin: "2rem 0",
      },
    },
  },
}));
