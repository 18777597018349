// Website
export const HOME_URL = "/"
export const PRICING_URL = "/pricing"
// Auth
export const SIGN_IN_URL = "/sign-in"
export const SIGN_UP_URL = "/sign-up"
export const FORGET_PASSWORD_URL = "/forget-password"
export const PASSWORD_RESET_URL = "/password-reset"
export const CHANGE_PASSWORD_URL = "/change-password"
export const VERIFY_ACCOUNT_URL = "/verify-account"
export const PRIVACY_POLICY_URL = "/privacy-policy"
export const REFUND_POLICY_URL = "/refund-policy"
export const TERMS_OF_USE_URL = "/terms-of-use"

export const DASHBOARD_URL = "/app"
export const APPOINTMENTS_URL = `${DASHBOARD_URL}/appointments`
export const APPOINTMENTS_VISITOR_LOG_URL = `${APPOINTMENTS_URL}/visitor-log`
export const APPOINTMENTS_ANALYTICS_URL = `${APPOINTMENTS_URL}/analytics`
export const APPOINTMENTS_ARCHIVE_URL = `${APPOINTMENTS_URL}/archive`

export const EVENTS_URL = `${DASHBOARD_URL}/events`
export const EVENT_MANAGE_URL = `${DASHBOARD_URL}/events/manage`
export const EVENTS_PARTICIPANTS_URL = `${EVENTS_URL}/participants`
export const EVENTS_ANALYTICS_URL = `${EVENTS_URL}/analytics`
export const EVENTS_ARCHIVE_URL = `${EVENTS_URL}/archive`

export const SETTINGS_URL = `${DASHBOARD_URL}/settings`

export const MY_VENDORS_URL = `${DASHBOARD_URL}/my-vendors`
export const VENDORS_DIRECTORY_URL = `${DASHBOARD_URL}/vendors/directory`

export const EVENT_REGISTRATION_URL = `registration`
export const EVENT_FEEDBACK_URL = `events`

export const SELF_CHECK_IN_URL = `self-check-in`
