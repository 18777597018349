import { SelfCheckInBox } from "./index.styles"
import { getEventBySlug, getEventById } from "api";
import { IEvents, IParticipant, ISession } from "types";
import { ToastMessage, TOASTER_TYPE } from "components";
import axios, { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Footer, Navbar } from "pages/Registration/components";
import { getEventCountdown } from "helpers";
import { BlueTimeIcon, CalendarIcon } from "assets/svg";
import dayjs from "dayjs";
import { ShowSelectedLocation } from "pages/Events/ManageEvents/components/Overview/components/EditEvent/ShowSelectedLocation";
import Lottie from "react-lottie-player";
import React from "react"
import RegDoneLottie from "../../assets/lottie/reg-done.json";
import { CheckInForm } from "./components";

export const SelfCheckIn = () => {
    const { slug } = useParams();
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [participantData, setParticipantData] = React.useState<IParticipant | null>(null);
    const { data, isLoading, error } = useQuery<IEvents>(
        ["eventBySlug", { slug }],
        getEventBySlug,
        {
            staleTime: Infinity,
            retry: 0,
            onError: (error) => {
                if (axios.isAxiosError(error)) {
                    if ((error as AxiosError).response?.status !== 404) {
                        ToastMessage(TOASTER_TYPE.ERROR, error.response?.data?.message);
                    }
                }
            },
        }
    );

    return (
        <SelfCheckInBox>
            <Navbar />
            <div className="px-3 px-lg-4 main">
                {data && (
                    <div className="wrapper mb-4 mb-lg-5">
                        <div className="imgHolder">
                            <img
                                src={data?.bannerUrl}
                                className="img-fluid"
                                alt={`${data?.name} event powered frondesq`}
                            />
                        </div>

                        <div className="cardWrap">
                            <h1 className="title">{data?.name}</h1>

                            <div className="usefulInfo">
                                <span className="eventType text-capitalize">
                                    {(data?.type as string).toLowerCase()}
                                </span>
                                <span className="virtualOrPhysical text-capitalize">
                                    {data?.location === "PHYSICAL"
                                        ? "Physical"
                                        : data?.location === "ZOOM"
                                            ? "Zoom"
                                            : data?.location === "TEAMS"
                                                ? "Team"
                                                : data?.location === "GOOGLE_MEET"
                                                    ? "Google meet"
                                                    : ""}
                                </span>
                                {data && (
                                    <span className="dateFromNow">
                                        {getEventCountdown(
                                            (data?.sessions as ISession[])[0].date as string,
                                            (data?.sessions as ISession[])[
                                                (data.sessions as ISession[] as ISession[]).length - 1
                                            ].date as string,
                                            (data?.sessions as ISession[])[0].startTime as string,
                                            (data?.sessions as ISession[])[
                                                (data?.sessions as ISession[]).length - 1
                                            ].endTime as string
                                        )}
                                    </span>
                                )}
                            </div>

                            <div className="dateAndTime">
                                <div className="date">
                                    <CalendarIcon />
                                    <span>
                                        {/* {dayjs((data?.sessions as ISession[])[0].date).format(
                        "dddd, MMM D, YYYY"
                      )} */}
                                        {data && (
                                            <>
                                                {data?.hasMultipleDays ? (
                                                    <>
                                                        {dayjs(
                                                            (data.sessions as ISession[])[0].date
                                                        ).format("MMM D")}{" "}
                                                        -{" "}
                                                        {dayjs(
                                                            (data.sessions as ISession[])[
                                                                (data.sessions as ISession[]).length - 1
                                                            ].date
                                                        ).format("MMM D, YYYY")}
                                                    </>
                                                ) : (
                                                    <>
                                                        {dayjs(
                                                            (data.sessions as ISession[])[0].date
                                                        ).format("dddd, MMM D, YYYY")}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </span>
                                </div>
                                <div className="date">
                                    <BlueTimeIcon />
                                    {dayjs((data?.sessions as ISession[])[0].startTime).format(
                                        "h:mm A"
                                    )}{" "}
                                    -{" "}
                                    {dayjs((data?.sessions as ISession[])[0].endTime).format(
                                        "h:mm A"
                                    )}
                                    (WAT)
                                </div>
                                <div className="location">
                                    {/* <Location /> */}
                                    {/* {data?.addressUrl || data?.address} */}
                                    <ShowSelectedLocation
                                        name={data?.location as string}
                                        hideIcon={(data?.location as string) === ""}
                                        physicalLocation={
                                            (data?.address as string) ||
                                                (data?.addressUrl as string)
                                                ? true
                                                : false
                                        }
                                        physicalLocationName={
                                            data?.location === "PHYSICAL"
                                                ? (data?.address as string)
                                                : data?.location === "ZOOM"
                                                    ? "Zoom"
                                                    : data?.location === "TEAMS"
                                                        ? "Team"
                                                        : data?.location === "GOOGLE_MEET"
                                                            ? "Google Meet"
                                                            : ""
                                        }
                                    />
                                </div>
                            </div>
                        </div>


                        {isSuccess && (
                            <div className="aboutWrap text-center" id="regSuccess">
                                <Lottie
                                    className="mx-auto d-block mb-0"
                                    play
                                    loop={1000}
                                    style={{
                                        width: "150px",
                                        height: "150px",
                                    }}
                                    animationData={RegDoneLottie}
                                />

                                <h1 className="aboutTitle mb-0">Check in successful</h1>
                                <p className="details px-lg-5 mx-lg-4">
                                    This email, <strong>{participantData?.email}</strong>
                                    {participantData?.eventPaymentTier && (
                                        <>{" "}
                                            with ticket type <strong className="bg-warning px-2"> {participantData?.eventPaymentTier?.name}</strong>
                                            
                                        </>
                                    )}
                                   {" "}has been checked in for{" "}
                                    <span className="border-bottom border-dark">{data?.name} event.</span>
                                    <br />
                                    <br />
                                  <small> Present this as a confirmation of your check in.</small>
                                </p>
                            </div>
                        )}
                    </div>
                )}

                {!isSuccess && (
                    <div className="form-wrap">
                        {data && (
                            <CheckInForm
                                eventDetails={data}
                                setIsSuccess={setIsSuccess}
                                setParticipantData={setParticipantData}
                            />
                        )}
                    </div>
                )}
            </div>
            <Footer />
        </SelfCheckInBox>
    )
}