import Lottie from "react-lottie-player";
import PaymentSuccess from "../../../../assets/lottie/payment-success.json";
import PaymentFailure from "../../../../assets/lottie/payment-failure.json";
import * as React from "react";
import { styled } from "@mui/material";

export const ResponseBox = styled("section")(({ theme }) => ({
  "& .textHolder": {
    textAlign: "center",
    padding: "2rem 0",

    "& .title": {
      fontSize: "1.15rem",
      fontWeight: 600,
      color: theme.palette.secondary.main,
      marginBottom: "1rem",
    },

    "& .details": {
      fontSize: "1rem",
      fontWeight: 400,
      color: "#424242",
    },
  },
}));

export type IResponseType = "Success" | "Failure" | "";

interface IProps {
  responseType: IResponseType;
}

export const PaymentResponse: React.FC<IProps> = ({ responseType }) => {
  return (
    <ResponseBox>
      {responseType && (
        <Lottie
          className="mx-auto d-block mb-1"
          play
          loop={500} // providing 1 makes it loop 2x
          style={{
            width: "250px",
            height: "220px",
          }}
          animationData={
            responseType === "Success" ? PaymentSuccess : PaymentFailure
          }
        />
      )}

      <div className="textHolder">
        <h3 className="title">
          Transaction {responseType === "Success" ? "Successful" : "Failed"}
        </h3>
        <p className="details">
          {responseType === "Success"
            ? "Your payment has been processed successfully"
            : "Your payment could not be processed. Please try again."}
        </p>
      </div>
    </ResponseBox>
  );
};
