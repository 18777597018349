import { FooterBox } from "./Footer.styles"
import { LogoTextLightBg } from "../../../../components"
import TwitterIcon from "../../../../assets/icon/logos_twitter.svg"
import InstagramIcon from "../../../../assets/icon/logos_instagram-icon.svg"
import LinkedInIcon from "../../../../assets/icon/logos_linkedin-icon.svg"
import { HOME_URL } from "../../../../helpers/routes.url"
import { Link } from "react-router-dom"

export const Footer = () => {
  return (
    <FooterBox>
      <div className="container-fluid">
        <div className="contentHolder">
          <div className="">
            <Link to={HOME_URL}>
              <LogoTextLightBg />
            </Link>
            <ul className="footerNoteHolder">
              <li>&copy; {new Date().getFullYear()} FronDesq</li>
              <li>
                <Link to="/terms-of-use">Terms &amp; Conditions</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/refund-policy">Refund Policy</Link>
              </li>
            </ul>
          </div>
          <div className="socialIconHolder">
            <a
              href="https://twitter.com/frondesq"
              target="_blank"
              rel="noreferrer"
            >
              <img src={TwitterIcon} alt="FronDesq on Twitter" />
            </a>
            <a
              href="https://instagram.com/frondesq"
              target="_blank"
              rel="noreferrer"
            >
              <img src={InstagramIcon} alt="FronDesq on Instagram" />
            </a>
            <a
              href="https://www.linkedin.com/company/frondesq"
              target="_blank"
              rel="noreferrer"
            >
              <img src={LinkedInIcon} alt="FronDesq on LinkedIn" />
            </a>
          </div>
        </div>
      </div>
    </FooterBox>
  )
}
