import { DatePicker } from "@mui/x-date-pickers"
import dayjs, { Dayjs } from "dayjs"
import * as React from "react"

interface IProps {
  initialValue: Date | Dayjs | string | null
  handleChange: (arg: any) => void
}

export const FDDatePicker: React.FC<IProps> = ({
  initialValue,
  handleChange,
}) => {
  const [dateValue, setDateValue] = React.useState<Dayjs | Date | null>(
    dayjs(initialValue)
  )
  const [isOpenDate, setIsOpenDate] = React.useState<boolean>(false)

  return (
    <DatePicker
      sx={{
        "& .MuiInput-input": {
          minWidth: "70px",
          width: "auto",
          maxWidth: "127px",
          padding: "0 0 2px",
        },

        "& .MuiInput-root:before": {
          content: "none",
        },

        "& input": {
          color: "#424242",
          fontSize: "0.875rem",
          fontWeight: 400,
          padding: "0 !important",
          height: "auto",
        },
      }}
      format="LL"
      value={dayjs(initialValue)}
      onChange={handleChange}
      open={isOpenDate}
      onClose={() => setIsOpenDate(false)}
      slotProps={{
        textField: {
          variant: "standard",
          onClick: () => setIsOpenDate(true),
        },
      }}
      slots={{ openPickerButton: () => null }}
      disablePast
    />
  )
}
