import { FDInput, FDPButton, FDSelect, TOASTER_TYPE, ToastMessage } from "components"
import { Formik } from "formik"
import React from "react"
import { IEvents, IParticipant, ISession } from "types"
import * as Yup from "yup"
import { useQuery, useMutation } from "@tanstack/react-query"
import { getAnEventParticipantByEmail, selfCheckInParticipant } from "api"
import { RegistrationFormBox } from "pages/Registration/components/form/styles"
import dayjs from "dayjs"
import { CircularProgress } from "@mui/material"
import { SuccessCheckIcon } from "assets/svg"
import { validateEmail } from "helpers"
import axios from "axios"

interface IProps {
    eventDetails: IEvents
    setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>
    setParticipantData: React.Dispatch<React.SetStateAction<IParticipant | null>>
}

const validationSchema = Yup.object({
    email: Yup.string()
        .email("Invalid email address")
        .required("Enter your email address"),
    sessionId: Yup.string().required("Select a session")
})

export const CheckInForm: React.FC<IProps> = ({
    eventDetails,
    setIsSuccess,
    setParticipantData
}) => {
    const [participantEmail, setParticipantEmail] = React.useState<string>("")
    const { data: participant_data, isLoading, isFetched, isSuccess, refetch } = useQuery({
        queryKey: [`GetAnEventParticipantByEmail${participantEmail}`],
        queryFn: () => getAnEventParticipantByEmail({
            id: eventDetails.id as string,
            search: participantEmail
        }),
        enabled: !!participantEmail && validateEmail(participantEmail),
        onError: (error) => {
            if (axios.isAxiosError(error)) {
                ToastMessage(TOASTER_TYPE.ERROR, error.response?.data?.message)
            }
        }
    })
    const onSelfCheckInParticipant = useMutation({
        mutationKey: ["SelfCheckInParticipant"],
        mutationFn: selfCheckInParticipant,
        onSuccess: () => {
            ToastMessage(TOASTER_TYPE.SUCCESS, "Check in successful")
            setIsSuccess(true)
        },
        onError: (error) => {
            if (axios.isAxiosError(error)) {
                ToastMessage(TOASTER_TYPE.ERROR, error.response?.data?.message)
            }
        }
    })

    React.useEffect(() => {
        if(isFetched && isSuccess && participant_data && !participant_data?.participants[0]){
            ToastMessage(TOASTER_TYPE.ERROR, "Your email is not registered for this event")
        }
    }, [isSuccess])

    React.useEffect(() => {
        if (participantEmail && validateEmail(participantEmail)) {
            refetch()
        }
    }, [participantEmail])


    const initialValues = {
        email: "",
        sessionId: "",
        participantId: "",
        uniqueId: "",
        eventId: eventDetails.id as string,
    }

    const formatSessions = eventDetails &&
        eventDetails.sessions &&
        eventDetails.sessions.map((session: ISession, index) => ({
            ...session,
            name: `${dayjs(session.date).format("D MMM, YYYY")} - Day ${index + 1}`,
            value: session.id
        }))


    const participantData = participant_data && participant_data?.participants ? participant_data?.participants[0] : null

 

    return (
        <RegistrationFormBox>
            <Formik
                validateOnMount
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, { resetForm }) => {
                    setParticipantData(participantData)
                    const data = {
                        ...values,
                        participantId: participantData.id
                    }
                    onSelfCheckInParticipant.mutate(data)
                }}
            >
                {({
                    touched,
                    errors,
                    handleBlur,
                    handleSubmit,
                    handleChange,
                    isValid,
                    values: { email, sessionId, uniqueId },
                }) => {
                    return (
                        <form
                            id="regSection"
                            className="registrationCard"
                            onSubmit={handleSubmit}
                        >
                            <div className="d-flex align-items-center gap-2 title_holder">
                                <h1 className="mb-0">Self check-in</h1>
                            </div>

                            <div className="details pb-0">
                                Please enter your email and select a session/day to check in.

                                <small className="paid-instructions">
                                    Ensure you use the same email you provided during registration
                                </small>
                            </div>

                            <div className="form-inputs gap-3">
                                <div className="d-flex flex-column gap-1 gap-lg-2 w-100">
                                    <FDInput
                                        type={"email"}
                                        fullWidth
                                        name="email"
                                        label="Email Address"
                                        placeholder="Enter your email address"
                                        value={email}
                                        onChange={e => {
                                            handleChange(e)
                                            setParticipantEmail(e.target.value)
                                        }}
                                        error={touched.email && Boolean(errors.email)}
                                    />
                                    {validateEmail(email) && (
                                        <>
                                            {(!isFetched && !isSuccess && isLoading) && (
                                                <div className="d-flex gap-2 align-items-center loadingText">
                                                    <CircularProgress size={20} />
                                                    Verifying email...
                                                </div>
                                            )}
                                            {(isSuccess && !isLoading && participantData) && (
                                                <div className="verifiedText flex align-items-center gap-3">
                                                    <SuccessCheckIcon />
                                                    {participantData?.firstName} {participantData?.lastName}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>


                                {(eventDetails.validatePass && email && participantData) && (
                                    <FDInput
                                        type={"text"}
                                        fullWidth
                                        name="uniqueId"
                                        label="Event pass code"
                                        placeholder="Enter your event pass code"
                                        value={uniqueId}
                                        onChange={handleChange}
                                        error={touched.uniqueId && Boolean(errors.uniqueId)}
                                    />
                                )}

                                <FDSelect
                                    useValue
                                    hasObjects
                                    selectableObjects={formatSessions}
                                    label="Select session"
                                    name="sessionId"
                                    value={sessionId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.sessionId && Boolean(errors.sessionId)}
                                />

                                <>
                                    {console.log({ isValid })}
                                </>

                                <FDPButton
                                    isDisabled={
                                        !isValid ||
                                        onSelfCheckInParticipant.isLoading ||
                                        (eventDetails.validatePass ? uniqueId.length === 4 ? false : true : false)
                                    }
                                    loading={onSelfCheckInParticipant.isLoading}
                                    text="Check in"
                                    type="submit"
                                />
                            </div>
                        </form>

                    )
                }}
            </Formik>
        </RegistrationFormBox>
    )
}