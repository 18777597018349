import { ComingSoonBox } from "./comingSoon.styles";
import { FDPButton } from "..";
import Lottie from "react-lottie-player";
import ComingSoonLottie from "../../../assets/lottie/coming-soon.json";

export const ComingSoon = () => {
  return (
    <ComingSoonBox>
      <div className="textContainer">
        <Lottie
          className="mx-auto d-block mb-3"
          play
          loop={1000} // providing 1 makes it loop 2x
          style={{
            width: "300px",
            height: "300px",
          }}
          animationData={ComingSoonLottie}
        />

        <p className="details">
          We are excited about what we are building and can’t wait to share with
          you soon.
        </p>
        <FDPButton
          text="Notify me when this feature is available"
          btnVariant="Primary"
        />
      </div>
    </ComingSoonBox>
  );
};
