import { FullPageBox } from "./loader.styles";
import "./logo-animate.css";
import { FrondesqLogoIdIcon } from "../../../../assets/svg";

export const FullPageLoader = () => {
  return (
    <FullPageBox>
      <div className="logoContainer">
        <div className="logoContainer logoAnimate">
         {/* <div className="text">Loading...</div>  */}
         <FrondesqLogoIdIcon />
        </div>
      </div>
    </FullPageBox>
  );
};
