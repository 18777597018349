import axiosInstance from "./instance";
import { IAdminDetails } from "../types";

const accessToken = localStorage.getItem("FD_AU_TK");

// Admin
export const createAdmin = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: "admin",
      method: "POST",
      data: data,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const getAdmins = async (data: any): Promise<IAdminDetails[]> => {
  try {
    const response = await axiosInstance({
      url: "admin",
      method: "GET",
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAdminById = async (data: any): Promise<IAdminDetails> => {
  try {
    const response = await axiosInstance({
      url: `admin/${data.queryKey[1]}`,
      method: "GET",
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateAdmin = async (data: IAdminDetails) => {
  try {
    const response = await axiosInstance({
      url: `admin/${data.id}`,
      method: "PATCH",
      data: data,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteAdmin = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: `admin/${data.id}`,
      method: "DELETE",
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const changeAdminDefaultPassword = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: "admin/change-default-password",
      method: "POST",
      data: data,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const resetAdminPassword = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: "admin/reset-admin-password",
      method: "POST",
      data: data,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const changeAdminPassword = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: "admin/change-password",
      method: "POST",
      data: data,
    });

    return response;
  } catch (error) {
    throw error;
  }
};
