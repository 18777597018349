// import { render } from "@testing-library/react";
import {
  DASHBOARD_URL,
  APPOINTMENTS_URL,
  APPOINTMENTS_VISITOR_LOG_URL,
  APPOINTMENTS_ANALYTICS_URL,
  APPOINTMENTS_ARCHIVE_URL,
  EVENTS_URL,
  EVENTS_PARTICIPANTS_URL,
  EVENTS_ANALYTICS_URL,
  EVENTS_ARCHIVE_URL,
  SETTINGS_URL,
  MY_VENDORS_URL,
  VENDORS_DIRECTORY_URL,
} from "./routes.url";
import { ProfileIcon, GroupProfileIcon } from "../assets/svg";

export interface INavigator {
  title: string;
  link?: string | null;
  icon?: any;
  children?: INavChildren[] | any | null;
}

export interface INavChildren {
  subTitle: string;
  link?: string;
  icon: any | null;
}

const renderSVG = (svg: any) => {
  return svg;
};

export const navigatorData: INavigator[] = [
  {
    title: "Dashboard",
    link: DASHBOARD_URL,
    icon: renderSVG(
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.5 10.8333H9.16667V2.5H2.5V10.8333ZM2.5 17.5H9.16667V12.5H2.5V17.5ZM10.8333 17.5H17.5V9.16667H10.8333V17.5ZM10.8333 2.5V7.5H17.5V2.5H10.8333Z"
          fill="#0B61DD"
        />
      </svg>
    ),
    children: null,
  },

  {
    title: "All Events",
    link: EVENTS_URL,
    icon: renderSVG(
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.49935 0.833984V2.50065H12.4993V0.833984H14.166V2.50065H17.4993C17.9596 2.50065 18.3327 2.87375 18.3327 3.33398V16.6673C18.3327 17.1276 17.9596 17.5007 17.4993 17.5007H2.49935C2.03912 17.5007 1.66602 17.1276 1.66602 16.6673V3.33398C1.66602 2.87375 2.03912 2.50065 2.49935 2.50065H5.83268V0.833984H7.49935ZM16.666 9.16732H3.33268V15.834H16.666V9.16732ZM6.66602 11.6673V13.334H4.99935V11.6673H6.66602ZM14.9993 11.6673V13.334H8.33268V11.6673H14.9993ZM5.83268 4.16732H3.33268V7.50065H16.666V4.16732H14.166V5.83398H12.4993V4.16732H7.49935V5.83398H5.83268V4.16732Z"
          fill="#787878"
        />
      </svg>
    ),
    children: null,
  },

  {
    title: "Vendors",
    link: null,
    icon: <GroupProfileIcon />,
    children: [
      {
        subTitle: "Directory",
        link: VENDORS_DIRECTORY_URL,
        icon: null,
      },
      {
        subTitle: "My Vendor",
        link: MY_VENDORS_URL,
        icon: null,
      },
    ],
  },

  {
    title: "Participants",
    link: null,
    icon: <ProfileIcon />,
    children: [
      {
        subTitle: "Register",
        link: EVENTS_PARTICIPANTS_URL,
        icon: renderSVG(
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="8" height="8" rx="4" fill="#16A34F" />
          </svg>
        ),
      },
      {
        subTitle: "Archived",
        link: EVENTS_ARCHIVE_URL,
        icon: renderSVG(
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="8" height="8" rx="4" fill="#B31414" />
          </svg>
        ),
      },
    ],
  },

  // {
  //   title: "Analytics",
  //   link: EVENTS_ANALYTICS_URL,
  //   icon: renderSVG(
  //     <svg
  //       width="20"
  //       height="20"
  //       viewBox="0 0 20 20"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M4.16667 2.5V15.8333H17.5V17.5H2.5V2.5H4.16667ZM16.9107 5.24408L18.0892 6.42259L13.3333 11.1785L10.8333 8.67917L7.25593 12.2559L6.07741 11.0774L10.8333 6.32149L13.3333 8.82083L16.9107 5.24408Z"
  //         fill="#787878"
  //       />
  //     </svg>
  //   ),
  //   children: null,
  // },

  {
    title: "Account",
    link: SETTINGS_URL,
    icon: renderSVG(
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M9.99984 1.66602C14.6022 1.66602 18.3332 5.39697 18.3332 9.99935C18.3332 14.6017 14.6022 18.3327 9.99984 18.3327C5.39746 18.3327 1.6665 14.6017 1.6665 9.99935C1.6665 5.39697 5.39746 1.66602 9.99984 1.66602ZM10.1329 13.3327C8.43675 13.3327 6.90969 14.0566 5.8438 15.2123C6.9835 16.1222 8.42817 16.666 9.99984 16.666C11.6411 16.666 13.1438 16.0729 14.3053 15.0894C13.2462 14.0055 11.7681 13.3327 10.1329 13.3327ZM9.99984 3.33268C6.31794 3.33268 3.33317 6.31745 3.33317 9.99935C3.33317 11.5082 3.83442 12.8999 4.67948 14.0172C6.04683 12.5694 7.98437 11.666 10.1329 11.666C12.2033 11.666 14.0776 12.5048 15.4347 13.8612C16.2103 12.7716 16.6665 11.4388 16.6665 9.99935C16.6665 6.31745 13.6818 3.33268 9.99984 3.33268ZM9.99984 4.16602C11.8408 4.16602 13.3332 5.6584 13.3332 7.49935C13.3332 9.34027 11.8408 10.8327 9.99984 10.8327C8.15889 10.8327 6.6665 9.34027 6.6665 7.49935C6.6665 5.6584 8.15889 4.16602 9.99984 4.16602ZM9.99984 5.83268C9.07934 5.83268 8.33317 6.57887 8.33317 7.49935C8.33317 8.41985 9.07934 9.16602 9.99984 9.16602C10.9203 9.16602 11.6665 8.41985 11.6665 7.49935C11.6665 6.57887 10.9203 5.83268 9.99984 5.83268Z"
          fill="#787878"
        />
      </svg>
    ),
    children: null,
  },

  // For All modules with children

  // {
  //   title: "Appointments",
  //   link: null,
  //   children: [
  //     {
  //       subTitle: "All Appointments",
  //       link: APPOINTMENTS_URL,
  //       icon: renderSVG(
  //         <svg
  //           width="20"
  //           height="20"
  //           viewBox="0 0 20 20"
  //           fill="none"
  //           xmlns="http://www.w3.org/2000/svg"
  //         >
  //           <path
  //             d="M5.83268 4.16732V1.66732C5.83268 1.20708 6.20578 0.833984 6.66602 0.833984H13.3327C13.7929 0.833984 14.166 1.20708 14.166 1.66732V4.16732H17.4993C17.9596 4.16732 18.3327 4.54042 18.3327 5.00065V16.6673C18.3327 17.1276 17.9596 17.5007 17.4993 17.5007H2.49935C2.03912 17.5007 1.66602 17.1276 1.66602 16.6673V5.00065C1.66602 4.54042 2.03912 4.16732 2.49935 4.16732H5.83268ZM13.3327 12.5007H10.8327V13.334H9.16602V12.5007H6.66602V15.834H13.3327V12.5007ZM6.66602 5.83398V10.834H9.16602V10.0007H10.8327V10.834H13.3327V5.83398H6.66602ZM4.99935 10.834V5.83398H3.33268V10.834H4.99935ZM14.9993 10.834H16.666V5.83398H14.9993V10.834ZM4.99935 12.5007H3.33268V15.834H4.99935V12.5007ZM14.9993 12.5007V15.834H16.666V12.5007H14.9993ZM7.49935 2.50065V4.16732H12.4993V2.50065H7.49935Z"
  //             fill="#787878"
  //           />
  //         </svg>
  //       ),
  //     },
  //     {
  //       subTitle: "Visitors’ Log",
  //       link: APPOINTMENTS_VISITOR_LOG_URL,
  //       icon: renderSVG(
  //         <svg
  //           width="20"
  //           height="20"
  //           viewBox="0 0 20 20"
  //           fill="none"
  //           xmlns="http://www.w3.org/2000/svg"
  //         >
  //           <path
  //             d="M1.66602 18.334C1.66602 14.6521 4.65078 11.6673 8.33268 11.6673C12.0146 11.6673 14.9993 14.6521 14.9993 18.334H13.3327C13.3327 15.5726 11.0941 13.334 8.33268 13.334C5.57126 13.334 3.33268 15.5726 3.33268 18.334H1.66602ZM8.33268 10.834C5.57018 10.834 3.33268 8.59648 3.33268 5.83398C3.33268 3.07148 5.57018 0.833984 8.33268 0.833984C11.0952 0.833984 13.3327 3.07148 13.3327 5.83398C13.3327 8.59648 11.0952 10.834 8.33268 10.834ZM8.33268 9.16732C10.1743 9.16732 11.666 7.67565 11.666 5.83398C11.666 3.99232 10.1743 2.50065 8.33268 2.50065C6.49102 2.50065 4.99935 3.99232 4.99935 5.83398C4.99935 7.67565 6.49102 9.16732 8.33268 9.16732ZM15.2358 12.253C17.553 13.2974 19.166 15.6273 19.166 18.334H17.4993C17.4993 16.304 16.2896 14.5566 14.5517 13.7732L15.2358 12.253ZM14.6628 2.84499C16.328 3.53151 17.4993 5.17033 17.4993 7.08398C17.4993 9.47582 15.6695 11.4383 13.3327 11.6487V9.97115C14.7466 9.76915 15.8327 8.55398 15.8327 7.08398C15.8327 5.93344 15.1673 4.93901 14.2002 4.46428L14.6628 2.84499Z"
  //             fill="#787878"
  //           />
  //         </svg>
  //       ),
  //     },
  //     {
  //       subTitle: "Analytics",
  //       link: APPOINTMENTS_ANALYTICS_URL,
  //       icon: renderSVG(
  //         <svg
  //           width="20"
  //           height="20"
  //           viewBox="0 0 20 20"
  //           fill="none"
  //           xmlns="http://www.w3.org/2000/svg"
  //         >
  //           <path
  //             d="M4.16667 2.5V15.8333H17.5V17.5H2.5V2.5H4.16667ZM16.9107 5.24408L18.0892 6.42259L13.3333 11.1785L10.8333 8.67917L7.25593 12.2559L6.07741 11.0774L10.8333 6.32149L13.3333 8.82083L16.9107 5.24408Z"
  //             fill="#787878"
  //           />
  //         </svg>
  //       ),
  //     },
  //     {
  //       subTitle: "Archive",
  //       link: APPOINTMENTS_ARCHIVE_URL,
  //       icon: renderSVG(
  //         <svg
  //           width="20"
  //           height="20"
  //           viewBox="0 0 20 20"
  //           fill="none"
  //           xmlns="http://www.w3.org/2000/svg"
  //         >
  //           <path
  //             d="M9.99935 1.66602C14.6017 1.66602 18.3327 5.39697 18.3327 9.99935C18.3327 14.6017 14.6017 18.3327 9.99935 18.3327C5.39697 18.3327 1.66602 14.6017 1.66602 9.99935H3.33268C3.33268 13.6813 6.31745 16.666 9.99935 16.666C13.6813 16.666 16.666 13.6813 16.666 9.99935C16.666 6.31745 13.6813 3.33268 9.99935 3.33268C7.70787 3.33268 5.68641 4.4888 4.48644 6.24955L6.66602 6.24935V7.91602H1.66602V2.91602H3.33268L3.33259 4.99867C4.85297 2.97497 7.27329 1.66602 9.99935 1.66602ZM10.8327 5.83268L10.8325 9.65352L13.5348 12.3563L12.3563 13.5348L9.16585 10.3435L9.16602 5.83268H10.8327Z"
  //             fill="#787878"
  //           />
  //         </svg>
  //       ),
  //     },
  //   ],
  // },

  // {
  //   title: "Events",
  //   link: null,
  //   children: [
  //     {
  //       subTitle: "All Events",
  //       link: EVENTS_URL,
  //       icon: renderSVG(
  //         <svg
  //           width="20"
  //           height="20"
  //           viewBox="0 0 20 20"
  //           fill="none"
  //           xmlns="http://www.w3.org/2000/svg"
  //         >
  //           <path
  //             d="M7.49935 0.833984V2.50065H12.4993V0.833984H14.166V2.50065H17.4993C17.9596 2.50065 18.3327 2.87375 18.3327 3.33398V16.6673C18.3327 17.1276 17.9596 17.5007 17.4993 17.5007H2.49935C2.03912 17.5007 1.66602 17.1276 1.66602 16.6673V3.33398C1.66602 2.87375 2.03912 2.50065 2.49935 2.50065H5.83268V0.833984H7.49935ZM16.666 9.16732H3.33268V15.834H16.666V9.16732ZM6.66602 11.6673V13.334H4.99935V11.6673H6.66602ZM14.9993 11.6673V13.334H8.33268V11.6673H14.9993ZM5.83268 4.16732H3.33268V7.50065H16.666V4.16732H14.166V5.83398H12.4993V4.16732H7.49935V5.83398H5.83268V4.16732Z"
  //             fill="#787878"
  //           />
  //         </svg>
  //       ),
  //     },
  //     {
  //       subTitle: "Participants",
  //       link: EVENTS_PARTICIPANTS_URL,
  //       icon: renderSVG(
  //         <svg
  //           width="20"
  //           height="20"
  //           viewBox="0 0 20 20"
  //           fill="none"
  //           xmlns="http://www.w3.org/2000/svg"
  //         >
  //           <path
  //             d="M3.33398 18.334C3.33398 14.6521 6.31875 11.6673 10.0007 11.6673C13.6826 11.6673 16.6673 14.6521 16.6673 18.334H15.0007C15.0007 15.5726 12.7621 13.334 10.0007 13.334C7.23923 13.334 5.00065 15.5726 5.00065 18.334H3.33398ZM10.0007 10.834C7.23815 10.834 5.00065 8.59648 5.00065 5.83398C5.00065 3.07148 7.23815 0.833984 10.0007 0.833984C12.7632 0.833984 15.0007 3.07148 15.0007 5.83398C15.0007 8.59648 12.7632 10.834 10.0007 10.834ZM10.0007 9.16732C11.8423 9.16732 13.334 7.67565 13.334 5.83398C13.334 3.99232 11.8423 2.50065 10.0007 2.50065C8.15898 2.50065 6.66732 3.99232 6.66732 5.83398C6.66732 7.67565 8.15898 9.16732 10.0007 9.16732Z"
  //             fill="#787878"
  //           />
  //         </svg>
  //       ),
  //     },
  //     {
  //       subTitle: "Analytics",
  //       link: EVENTS_ANALYTICS_URL,
  //       icon: renderSVG(
  //         <svg
  //           width="20"
  //           height="20"
  //           viewBox="0 0 20 20"
  //           fill="none"
  //           xmlns="http://www.w3.org/2000/svg"
  //         >
  //           <path
  //             d="M4.16667 2.5V15.8333H17.5V17.5H2.5V2.5H4.16667ZM16.9107 5.24408L18.0892 6.42259L13.3333 11.1785L10.8333 8.67917L7.25593 12.2559L6.07741 11.0774L10.8333 6.32149L13.3333 8.82083L16.9107 5.24408Z"
  //             fill="#787878"
  //           />
  //         </svg>
  //       ),
  //     },
  //     {
  //       subTitle: "Archive",
  //       link: EVENTS_ARCHIVE_URL,
  //       icon: renderSVG(
  //         <svg
  //           width="20"
  //           height="20"
  //           viewBox="0 0 20 20"
  //           fill="none"
  //           xmlns="http://www.w3.org/2000/svg"
  //         >
  //           <path
  //             d="M9.99935 1.66602C14.6017 1.66602 18.3327 5.39697 18.3327 9.99935C18.3327 14.6017 14.6017 18.3327 9.99935 18.3327C5.39697 18.3327 1.66602 14.6017 1.66602 9.99935H3.33268C3.33268 13.6813 6.31745 16.666 9.99935 16.666C13.6813 16.666 16.666 13.6813 16.666 9.99935C16.666 6.31745 13.6813 3.33268 9.99935 3.33268C7.70787 3.33268 5.68641 4.4888 4.48644 6.24955L6.66602 6.24935V7.91602H1.66602V2.91602H3.33268L3.33259 4.99867C4.85297 2.97497 7.27329 1.66602 9.99935 1.66602ZM10.8327 5.83268L10.8325 9.65352L13.5348 12.3563L12.3563 13.5348L9.16585 10.3435L9.16602 5.83268H10.8327Z"
  //             fill="#787878"
  //           />
  //         </svg>
  //       ),
  //     },
  //   ],
  // },
];
