export function formatCurrency(
  value: number,
  locale: string = "en-NG",
  currency: string = "NGN"
): string {
  const options: Intl.NumberFormatOptions = {
    style: "currency",
    currency,
  }

  // Customize options for NGN to remove decimal places
  if (currency === "NGN") {
    options.minimumFractionDigits = 0
    options.maximumFractionDigits = 0
  }

  return new Intl.NumberFormat(locale, options).format(value)
}
