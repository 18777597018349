import axiosInstance from "./instance";

// Participant
export const createParticipant = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: "participants",
      method: "POST",
      data: data,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const createTempParticipant = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: "participants/temp",
      method: "POST",
      data: data,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const getParticipants = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: "participants",
      method: "GET",
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getParticipantById = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: `participants/${data.queryKey[1].id}`,
      method: "GET",
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const isParticipantRegistered = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: `participants/is-registered?eventId=${data.queryKey[1].eventId}&email=${data.queryKey[1].email}`,
      method: "GET",
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const exportParticipantData = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: `participants/event/${data.queryKey[1].eventId}/download`,
      method: "GET",
      responseType: "blob",
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateParticipant = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: `participants/update/${data.id}`,
      method: "PATCH",
      data: data,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const checkInParticipantWithOutPass = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: `participants/check-in`,
      method: "PATCH",
      data: data,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const CheckInParticipantWithCode = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: `participants/code-check-in`,
      method: "POST",
      data,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const selfCheckInParticipant = async (data: {
  eventId: string;
  participantId: string;
  sessionId: string;
  uniqueId?: string;
  selfCheckInToken?: string;
}) => {
  try {
    const response = await axiosInstance({
      url: `participants/self/check-in`,
      method: "PATCH",
      data: data,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getParticipantDetailsWithCode = async (data: any) => {
  const params = new URLSearchParams({
    eventId: data.queryKey[1].eventId,
  });

  try {
    const response = await axiosInstance({
      url: `participants/code/${data.queryKey[1].code}?${params}`,
      method: "GET",
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getParticipantsBySessionId = async (data: any): Promise<any> => {
  const params = new URLSearchParams({
    // eventId: data.queryKey[1].eventId,
    sessionId: data.queryKey[1].sessionId,
  });

  try {
    const response = await axiosInstance({
      url: `participants?${params}`, // tentative
      method: "GET",
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const searchParticipants = async (data: any): Promise<any> => {
  const params = new URLSearchParams({
    eventId: data.eventId,
    search: data.search,
  });

  try {
    const response = await axiosInstance({
      url: `participants/event/search?${params}`, // tentative
      method: "GET",
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadFileAsParticipant = async (data: any): Promise<any> => {
  const previousContentType = axiosInstance.defaults.headers["Content-Type"];

  // Set the Content-Type header to "multipart/form-data" for this request
  axiosInstance.defaults.headers["Content-Type"] = "multipart/form-data";

  const params = new URLSearchParams({
    eventId: data.eventId,
    email: data.email,
  });

  try {
    const response = await axiosInstance({
      url: `participants/upload/doc?${params}`,
      method: "POST",
      data,
    });

    // Reset the Content-Type header to its previous value after the request
    axiosInstance.defaults.headers["Content-Type"] = previousContentType;

    return response.data;
  } catch (error) {
    // Reset the Content-Type header in case of an error
    axiosInstance.defaults.headers["Content-Type"] = previousContentType;

    throw error;
  }
};
