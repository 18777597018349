import React from "react";
import { usePermissions } from "./PermissionContext";
import { NoPermission, CenterDialog } from "../../components";
import jwtDecode from "jwt-decode";
import { maskString } from "../../helpers";

interface IPermissionWrapper {
  requiredPermission: string | null;
  children?: React.ReactNode | JSX.Element | any | string;
  falseReturnType?: "ShowModal" | "MaskHash" | "Element";
  keyValue?: any;
}

type TSystemRole = "ADMIN" | "OWNER" | "MANAGER";

export const PermissionWrapper: React.FC<IPermissionWrapper> = ({
  requiredPermission,
  children,
  falseReturnType,
  keyValue,
}) => {
  const userPermissions: string[] = usePermissions();
  const [hasRequiredPermission, setHasRequiredPermission] =
    React.useState<boolean>(false);
  const [showDialog, setShowDialog] = React.useState(false);

  React.useEffect(() => {
    const storedToken = localStorage.getItem("FD_AU_TK");

    if (storedToken) {
      const decodeToken: any = jwtDecode(storedToken);
      const role: TSystemRole = decodeToken.role;

      if (role === "OWNER") {
        setHasRequiredPermission(true);
      } else if (role === "ADMIN" || role === "MANAGER") {
        if (requiredPermission === null) return;

        const isPermitted = userPermissions.includes(requiredPermission);
        setHasRequiredPermission(isPermitted);
      }
    } else {
      // Handle the case where the token doesn't exist in localStorage
      // You can set an appropriate default value or handle it as needed
      // For example, you can log the issue or request the user to log in.
      console.error("Token not found in localStorage.");
    }
  }, []);


  if (falseReturnType === "ShowModal") {
    return (
      <div
        key={keyValue}
        onClick={() => setShowDialog(!hasRequiredPermission && !showDialog)}
      >
        <>{children}</>

        <CenterDialog
          dialogProps={{
            open: showDialog,
            onClose: () => {
              setShowDialog(false);
            },
          }}
        >
          <NoPermission
            handleClose={() => {
              setShowDialog(false);
            }}
          />
        </CenterDialog>
      </div>
    );
  } else if (falseReturnType === "MaskHash") {
    return !hasRequiredPermission ? (
      <>{maskString(children as string)}</>
    ) : (
      <>{children}</>
    );
  } else if (falseReturnType === "Element") {
    return hasRequiredPermission ? <>{children}</> : null;
  }

  return null;
};
