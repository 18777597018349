import { FooterBox } from "./footer.styles";
import { FrondesqLogoIdIcon } from "../../../../assets/svg";
import TwitterIcon from "../../../../assets/icon/logos_twitter.svg";
import InstagramIcon from "../../../../assets/icon/logos_instagram-icon.svg";
import LinkedInIcon from "../../../../assets/icon/logos_linkedin-icon.svg";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
  const navigate = useNavigate();

  const handleRoute = (url: string) => navigate(url);
  
  return (
    <FooterBox>
      <div className="container-fluid">
        <div className="footerHolder">
          <div className="iconHolder">
            <FrondesqLogoIdIcon />
          </div>

          <ul className="footerLinkGroup">
            <li>Features</li>
            <li>Event Conveners</li>
            <li>Vendors</li>
            <li>Pricing</li>
            <li>Contact</li>
          </ul>

          <div className="socialIconHolder">
            <a href="https://twitter.com/frondesq" target="_blank">
              <img src={TwitterIcon} alt="FronDesq on Twitter" />
            </a>
            <a href="https://instagram.com/frondesq" target="_blank">
              <img src={InstagramIcon} alt="FronDesq on Instagram" />
            </a>
            <a href="https://www.linkedin.com/company/frondesq" target="_blank">
              <img src={LinkedInIcon} alt="FronDesq on LinkedIn" />
            </a>
          </div>
        </div>
      </div>
    </FooterBox>
  );
};
