import { styled } from "@mui/material";

export const FullPageBox = styled("section")(({ theme }) => ({
  position: "fixed",
  width: "100%",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "rgba(255,255,255, 0.85)",
  backdropFilter: "blur(15px)",
  zIndex: 500,

  "& .logoContainer": {
    width: "300px",
    display: "flex",
    alignItems: "baseline",
    // justifyContent: "center",

    "& .text": {
      color: "#0C0C0C",
      fontWeight: 600,
      fontSize: "1.25rem",
    },
  },
}));
