import FDLogo1 from "../../../assets/logo/frondesq-logo.svg";
import FDLogoDarkBg from "../../../assets/logo/frondesq-logo-dark-bg.svg"
import * as React from "react"; 

interface IProps {
  width?: string;
}

export const LogoTextLightBg: React.FC<IProps> = ({ width }) => {
  return (
    <img
      src={FDLogo1}
      alt="FronDesq logo"
      style={{
        width: width ? `${width}px` : "unset",
      }}
    />
  );
};

export const LogoTextDarkBg: React.FC<IProps> = ({ width }) => {
  return (
    <img
      src={FDLogoDarkBg}
      alt="FronDesq logo"
      style={{
        width: width ? `${width}px` : "unset",
      }}
    />
  );
};
