import { NavBox } from "./NavBar.styles";
import { LogoTextDarkBg } from "../../../../components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import {
  FDPButton,
  PopoverComponent,
  DropDownForPopOver,
} from "../../../../components";
import { IconButton } from "@mui/material";
import { MenuIcon, ChervonArrowDownIcon } from "../../../../assets/svg";
import AvatarImg from "../../../../assets/icon/Avatars.svg";
import * as React from "react";
import { TSelectableMode } from "../../../../types";
import {
  HOME_URL,
  SIGN_UP_URL,
  SIGN_IN_URL,
  PRICING_URL,
} from "../../../../helpers/routes.url";
import { scroller } from "react-scroll";

interface IProps {
  handleModeChange: (arg: TSelectableMode) => void;
  selectedMode: TSelectableMode;
}

const modeList = [
  {
    name: "Convener",
    value: "Convener",
  },
  {
    name: "Vendor",
    value: "Vendor",
  },
];

export const NavBar: React.FC<IProps> = ({
  handleModeChange,
  selectedMode,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isVisible, setIsVisible] = React.useState<boolean>(false);

  const handleToggle = () => {
    setIsVisible(!isVisible);
  };

  const disableForVendorInPricingPage = (value: TSelectableMode) => {
    if (pathname !== PRICING_URL) return;

    // if (value === "Vendor") return "disabledElement";
  };

  const handleScrollTo = (sectionId: string) => {
    scroller.scrollTo(sectionId, {
      duration: 500,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: 0, // Adjust as needed
    });
  };

  const handleRoute = (link: string, sectionId: string) => {
    navigate(link, { state: { goTo: sectionId } });
  };

  const renderModeList = () => {
    return (
      <PopoverComponent
        title={
          <IconButton className="convenerText">
            {selectedMode} <ChervonArrowDownIcon />
          </IconButton>
        }
        children={
          <DropDownForPopOver>
            {modeList.map(({ name, value }) => (
              <div
                className={`item ${disableForVendorInPricingPage(
                  value as TSelectableMode
                )}`}
                onClick={() => {
                  handleModeChange(value as TSelectableMode);
                }}
              >
                {name}
              </div>
            ))}
          </DropDownForPopOver>
        }
      />
    );
  };

  return (
    <NavBox>
      <div className="container-fluid">
        <div className="navItemContainer">
          <Link to={HOME_URL}>
            <LogoTextDarkBg />
          </Link>

          <div className="d-lg-none d-flex align-items-center gap-1 mobileToggler">
            {renderModeList()}

            <IconButton className="hamburgerMenu" onClick={handleToggle}>
              <MenuIcon />
            </IconButton>
          </div>

          <div className={`menuContainer ${isVisible ? "show" : ""}`}>
            <ul className="menuGroup">
              <li>
                <Link to="/" onClick={() => handleRoute(HOME_URL, "feature")}>
                  Feature
                </Link>
              </li>
              <li>
                <Link
                  className={pathname === PRICING_URL ? "active" : ""}
                  to={PRICING_URL}
                >
                  Pricing
                </Link>
              </li>
              <li>
                <Link
                  to={HOME_URL}
                  onClick={() => handleScrollTo("contactSection")}
                >
                  Contact
                </Link>
              </li>
            </ul>

            <div className="btnContainer">
              <div className="d-none d-lg-inline-block">{renderModeList()}</div>

              <FDPButton
                forRouting
                link={SIGN_IN_URL}
                text="Log in"
                btnVariant="PrimaryOutline"
              />

              <FDPButton
                forRouting
                link={SIGN_UP_URL}
                text="Sign up"
                btnVariant="Primary"
              />
            </div>
          </div>

          {/* <img src={AvatarImg} alt="User avatar"  /> */}
        </div>
      </div>
    </NavBox>
  );
};
