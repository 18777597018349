import React from "react"
import { IoStar } from "react-icons/io5"
import { ReviewRating } from "types"

export type StarsProps = {
  level: ReviewRating
}

const starLevelToNumber = (level: ReviewRating): number => {
  switch (level) {
    case "ONE":
      return 1
    case "TWO":
      return 2
    case "THREE":
      return 3
    case "FOUR":
      return 4
    case "FIVE":
      return 5
    default:
      return 0
  }
}

export const Stars: React.FC<StarsProps> = ({ level }) => {
  const numericLevel = starLevelToNumber(level)

  return (
    <div className="d-flex">
      {[...Array(5).keys()].map((_, index) => {
        const starIndex = index + 1
        return (
          <IoStar
            key={starIndex}
            size={"1rem"}
            fill={starIndex <= numericLevel ? "#F2BA1D" : "white"}
            stroke="#F2BA1D"
            strokeWidth={"1.5rem"}
            className="space-star star"
          />
        )
      })}
    </div>
  )
}
