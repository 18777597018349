import  axiosInstance  from "./instance";

// Auth
export const registerUser = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: "auth/register",
      method: "POST",
      data: data,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const loginUser = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: "auth/login",
      method: "POST",
      data: data,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const verifyUserEmail = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: "auth/verify-email",
      method: "POST",
      data: data,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const resendEmailVerification = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: "auth/resend-email-verification",
      method: "POST",
      data: data,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const requestPasswordReset = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: "auth/request-password-reset",
      method: "POST",
      data: data,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const resetUserPassword = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: "auth/reset-password",
      method: "POST",
      data: data,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const changePassword = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: "auth/change-password",
      method: "POST",
      data: data,
    });

    return response;
  } catch (error) {
    throw error;
  }
};
