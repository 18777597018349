import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const options: any = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

type ToastType = "error" | "info" | "success" | "warn";

export const ToastMessage = (header: TOASTER_TYPE, message: string) => {
  let type: ToastType;

  switch (header) {
    case "SUCCESS":
      type = "success";
      break;

    case "INFO":
      type = "info";
      break;

    case "ERROR":
      type = "error";
      break;

    case "WARN":
      type = "warn";
      break;

    default:
      type = "info";
      break;
  }

  toast[type](message, options);
};

export enum TOASTER_TYPE {
  SUCCESS = "SUCCESS",
  INFO = "INFO",
  ERROR = "ERROR",
  WARN = "WARN",
}
