import { PageTitleBox } from "./PageTitle.styles";
import { FDPButton } from "..";
import * as React from "react";
import dayjs from "dayjs";

interface IProps {
  title: string | React.ReactNode;
  btnText?: string | null;
  datePosition?: "left" | "right";
  click?: () => void;
}

export const PageTitle: React.FC<IProps> = ({
  title,
  btnText,
  datePosition,
  click,
}) => {
  const renderDateTitle = () => {
    return (
      <div
        className={`${
          datePosition === "left" ? "text-start" : ""
        }  dateContainer`}
      >
        <div className="day">{dayjs(new Date()).format("dddd")},</div>
        <div className="date">{dayjs(new Date()).format("MMM D, YYYY")}</div>
      </div>
    );
  };

  return (
    <PageTitleBox>
      <div className="">
        <h3 className="title mb-0">{title}</h3>
        {datePosition === "left" && <>{renderDateTitle()}</>}
      </div>

      <div className="">
        {datePosition === "right" && <>{renderDateTitle()}</>}
        {btnText && (
          <FDPButton
            click={click}
            btnVariant="Primary"
            // buttonProps={{
            //   className: "primaryBtn",
            // }}
            text={btnText}
          />
        )}
      </div>
    </PageTitleBox>
  );
};
