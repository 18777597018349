export enum Recurs {
  Daily = "DAILY",
  Weekly = "WEEKLY",
  Monthly = "MONTHLY",
  Yearly = "YEARLY",
  Not_Recurring = "NOT_RECURRING",
}

export enum EventFeeType {
  Free = "FREE",
  Paid = "PAID",
  Donation = "DONATION",
}

export enum EventLocationType {
  Physical = "PHYSICAL",
  Zoom = "ZOOM",
  Teams = "TEAMS",
  Google_Meet = "GOOGLE_MEET",
}

export enum EventModeType {
  Live = "LIVE",
  Upcoming = "UPCOMING",
  Past = "PAST",
  Draft = "DRAFT",
  Published = "PUBLISHED",
}

export enum ChargeCarrier {
  PARTICIPANT = "PARTICIPANT",
  CONVENER = "CONVENER",
}

export enum EventCreationMode {
  CREATE = "CREATE",
  EDIT = "EDIT",
  CREATE_EDIT = "CREATE_EDIT",
}

export enum EventPaymentStatus {
  PAID = "PAID",
  PENDING = "PENDING",
  NOT_PAID = "NOT_PAID",
}

export enum ParticipatingAs {
  ATTENDEE = "ATTENDEE",
  SPEAKER = "SPEAKER",
  SPONSOR = "SPONSOR",
  PARTNER = "PARTNER",
  VENDOR = "VENDOR",
  CO_HOST = "CO_HOST",
  HOST = "HOST",
}

export enum StarLevel {
  ONE = "ONE",
  TWO = "TWO",
  THREE = "THREE",
  FOUR = "FOUR",
  FIVE = "FIVE",
}

export enum PaymentPurpose {
  ADMIN_SLOT = "ADMIN_SLOT",
  EVENT = "EVENT",
}