import { styled } from "@mui/material";
import * as React from "react";

interface IProps {
  children: React.ReactNode;
}

export const DropDownForPopOver: React.FC<IProps> = ({ children }) => (
  <DropDownBox>
    <div className="popover-child border">{children}</div>
  </DropDownBox>
);

export const DropDownBox = styled("section")(({ theme }) => ({
  "& .popover-child": {
    boxShadow: "0px 24px 64px rgba(156, 160, 167, 0.24)",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
  },

  "& .popover-child a, .popover-child .item": {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    padding: "0.8rem 1.5rem",
    color: "#787878",
    transition: "all 0.3s ease-in-out",
    textDecoration: "none",
    fontSize: "0.875rem",
    fontWeight: "600",

    "& svg": {
      width: "1.15rem",
      height: "1.15rem",

      "& path": {
        transition: "all 0.3s ease-in-out",
        fill: "#787878",
      },
    },

    "&:hover": {
      backgroundColor: "#E2EDFE",
      color: "#0C0C0C",

      "& svg": {
        "& path": {
          fill: "#0C0C0C",
        },
      },
    },
  },

  "& .popover-child a, .popover-child .dangerText": {
    color: "#F71C1C",

    "& span, :hover span": {
      color: "#F71C1C",
    },

    "& svg path, :hover svg path": {
      fill: "#F71C1C",
    },
  },
}));
