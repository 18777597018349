import { styled } from "@mui/material";

export const TableBox = styled("div")(({ theme }) => ({
  // height: "77vh",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",

  "& .MuiDataGrid-root": {
    fontSize: "0.875rem",
    backgroundColor: " #FFFFFF",
    borderWidth: "0",
  },

  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#F5F5F5",

    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 600,
      color: "#787878",
      textTransform: "uppercase",
      marginRight: ".5rem",
    },
  },

  "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within":
    {
      outline: "none",
    },

  "& .status": {
    borderRadius: "100px",
    textAlign: "center",
    fontWeight: 600,
    padding: "0.25rem .5rem",
    width: "85px",
    fontSize: ".8rem",
    lineHeight: "1.4 !important",
    height: "fit-content",
  },

  "& .success-status": {
    color: "#17B557",
    backgroundColor: "#D2F9E2",
    border: "1px solid #14994A",
  },

  "& .secondary-status": {
    color: "#787878",
    backgroundColor: "#F5F5F5",
    border: "1px solid #787878",
  },

  "& .danger-status": {
    color: "#B21414",
    backgroundColor: "#FDDFDF",
    border: "1px solid #B21414",
  },

  "& .info-status": {
    color: "#001935",
    backgroundColor: "#FAE3A5",
  },

  "& .view-details": {
    color: "1rem",
    fontWeight: 600,
    textDecoration: "underline",
  },

  "& .MuiDataGrid-root .MuiDataGrid-columnHeader": {
    padding: "0 1rem",

    // "&:first-child": {
    //   padding: "0",
    // },
  },

  "& .MuiDataGrid-root .MuiDataGrid-cell": {
    textTransform: "capitalize",
    padding: "0 1rem",
    color: "#424242 !important",
    fontSize: "0.875rem !important",
    fontWeight: "600 !important",
  },

  "& .tableBtn": {
    textTransform: "Capitalize",
    padding: "0.5rem 1rem",
    borderStyle: "solid",
    borderWidth: "1.5px",
    borderColor: theme.palette.primary.main,
    background: "transparent",
    color: theme.palette.primary.main,
  },

  "& .MuiTablePagination-toolbar": {
    "& p": {
      marginBottom: "0 !important",
    },
  },

  "& .paginationContainer": {
    padding: "0.5rem 0.5rem 3.5rem",
    display: "flex",
    justifyContent: "flex-end",
    background: "#ffffff",
  },
}));
