import { styled } from "@mui/material";

export const BookADemoBox = styled("section")(({ theme }) => ({
  padding: "4rem 0 5rem",

  "& .textHolder": {
    textAlign: "center",
    margin: "0 4rem",

    "& .title": {
      color: theme.palette.secondary.main,
      fontWeight: 600,
      fontSize: "3.5rem",
      marginBottom: "1rem",
    },

    "& .details": {
      color: "#424242",
      fontWeight: 400,
      margin: "0 4rem 2.5rem",
    },

    "& .btnContainer": {
      display: "flex",
      gap: "0 1.25rem",
      alignItems: "center",
      justifyContent: "center",
    },
  },

  "@media screen and (max-width: 1199.9px)": {
    padding: "4rem 0",

    "& .textHolder": {
      margin: "0 1rem",

      "& .title": {
        fontSize: "2.75rem",
        marginBottom: "1.5rem",
      },
    },
  },

  "@media screen and (max-width: 767.9px)": {
    padding: "4rem 0",

    "& .textHolder": {
      margin: "0",

      "& .title": {
        fontSize: "2.25rem",
      },

      "& .details": {
        margin: "0 1rem 2.5rem",
      },


    },
  },
}));
