import React, { useEffect } from "react"

export const ScrollToTop: React.FC = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }, [])

  return null // No need to render anything
}
