import React from "react";
import { PermissionContext } from "./PermissionContext";
import jwtDecode from "jwt-decode";

interface IProps {
  children: React.ReactNode;
}

const PermissionProvider: React.FC<IProps> = ({ children }) => {
  const [userPermissions, setUserPermissions] = React.useState<string[]>([]);

  React.useEffect(() => {
    const storedToken = localStorage.getItem("FD_AU_TK");

    if (storedToken) {
      const decodeToken: any = jwtDecode(storedToken);
      const permissions = decodeToken.permissions;

      setUserPermissions(permissions);
    } else {
      // what you wish to do if no token
    }
  }, []);

  return (
    <PermissionContext.Provider value={userPermissions as any}>
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionProvider;

// Call Reset function in logout
export const useResetPermissions = () => {
  const context: any = React.useContext(PermissionContext);
  if (!context) {
    throw new Error(
      "useResetPermissions must be used within a PermissionProvider"
    );
  }
  return context?.resetPermissions;
};
