import { styled } from "@mui/material"

export const ComplianceBox = styled("div")(({ theme }) => ({
  "& .headerSection": {
    textAlign: "center",
    paddingTop: "3.5rem",
    paddingBottom: "3.5rem",

    "& .title": {
      fontWeight: 800,
      fontSize: "4rem",
      color: theme.palette.secondary.main,
    },

    "& .detail": {
      fontSize: "1.5rem",
      fontWeight: 400,
    },
  },

  "& .sectionText": {
    marginBottom: "3.5rem",

    "& .title": {
      fontWeight: 700,
      fontSize: "1.5rem",
      color: "#272727",
      textTransform: "uppercase",
    },

    "& .detail": {
      fontSize: "1.125rem",
      fontWeight: 400,
      wordBreak: "break-word",
    },
  },

  h6: {
    fontWeight: "bold",
  },

  "&.container": {
    paddingBottom: "1rem",
  },
}))
