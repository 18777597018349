import axiosInstance from "./instance";
import { IUserDetails, IJwtDetails } from "../types";
// import { getDecodedToken } from "./instance";

export const getUser = async (): Promise<IUserDetails> => {
  // const decodedToken: IJwtDetails = getDecodedToken();

  try {
    const response = await axiosInstance({
      url: `auth/current-user`,
      method: "GET",
    });

    return response.data;
  } catch (error) {
    throw error
  }
}

export const updateUserDetails = async (data: IUserDetails) => {
  try {
    const response = await axiosInstance({
      url: `user`,
      method: "PATCH",
      data: data,
    });

    return response
  } catch (error) {
    throw error
  }
}

export const addPaymentDetails = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: `user/add-payout-record`,
      method: "PATCH",
      data: data,
    });

    return response
  } catch (error) {
    throw error
  }
}
