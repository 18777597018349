import Lottie from "react-lottie-player";
import EmptyStateLottie from "../../../assets/lottie/empty-state.json";
import { EmptyStateBox } from "./emptyState.styles";
import * as React from "react";

interface IProps {
  text: string;
}

export const EmptyState: React.FC<IProps> = ({ text }) => {
  return (
    <EmptyStateBox>
      <div className="textContainer">
        <Lottie
          className="mx-auto d-block mb-1"
          play
          loop={1000} // providing 1 makes it loop 2x
          style={{
            width: "250px",
            height: "220px",
          }}
          animationData={EmptyStateLottie}
        />

        <p className="details">{text}</p>
      </div>
    </EmptyStateBox>
  );
};
