import * as React from "react"
import { ComplianceBox } from "./compliance.styles"
import { Footer } from "pages/Website/components"
import { ScrollToTop } from "components"

export const PrivacyPolicy = () => {
  return (
    <React.Fragment>
      <ScrollToTop />
      <ComplianceBox className="container">
        <header className="headerSection">
          <h3 className="title">Privacy Policy</h3>
          {/* <p className="detail">Effective Date: 05/31/2020</p> */}
        </header>

        <section className="sectionText">
          <h5 className="title">Introduction</h5>
          <p className="detail">
            Welcome to <a href="/">Frondesq</a>, a self-service event management
            and ticketing platform operated by OneID Global Technologies Ltd
            (referred to as "Frondesq", "we", "us", or "our"). Your privacy is
            important to us, and this Privacy Policy explains how we collect,
            use, disclose, and protect your personal information when you use
            our Platform.
            <br />
            <br />
            By accessing or using our Platform, you agree to the terms of this
            Privacy Policy. If you do not agree with our practices, please do
            not use our Platform.
            <br />
            <br />
            <h5 className="title">1. Information We Collect</h5>
            We collect various types of information in connection with your use
            of our Platform, including:
            <br />
            <br />
            <h5>1.1 Personal Information</h5>
            <ul>
              <li>
                <b>Registration Information:</b> When you create an account, we
                may collect your name, email address, phone number, and other
                contact details.
              </li>
              <li>
                <b>Payment Information:</b> When you purchase tickets, we
                collect payment information, such as credit card details,
                billing address, and transaction history.
              </li>
            </ul>
            <h5>1.2 Non-Personal Information</h5>
            <ul>
              <li>
                <b>Usage Data:</b> We collect information about your
                interactions with our Platform, including IP addresses, browser
                types, referring/exit pages, and operating system information.
              </li>
              <li>
                <b>Cookies and Tracking Technologies:</b> We use cookies, web
                beacons, and similar technologies to collect information about
                your browsing activities on our Platform.
              </li>
            </ul>
          </p>

          <h5 className="title">2. How We Use Your Information</h5>
          <p className="detail">
            We use the information we collect for various purposes, including:
          </p>

          <h5>2.1 Non-Personal Information</h5>
          <p className="detail">
            <ul>
              <li>To operate and maintain our Platform.</li>
              <li>
                To process transactions and send you related information,
                including purchase confirmations and invoices.
              </li>
              <li>
                To provide customer support and respond to your inquiries.
              </li>
            </ul>
          </p>

          <h5>2.2 Communication</h5>
          <p className="detail">
            <ul>
              <li>
                To send you updates, marketing communications, and promotional
                offers.
              </li>
              <li>
                To communicate with you about your account, transactions, and
                events.
              </li>
            </ul>
          </p>

          <h5>2.3 Analytics and Personalization</h5>
          <p className="detail">
            <ul>
              <li>To analyze usage patterns and improve our Platform.</li>
              <li>
                To personalize your experience by presenting content and offers
                tailored to your interests.
              </li>
            </ul>
          </p>

          <h5>2.4 Legal Compliance</h5>
          <p className="detail">
            <ul>
              <li>
                To comply with legal obligations and protect our rights and
                interests.
              </li>
            </ul>
          </p>
        </section>

        <section className="sectionText">
          <h5 className="title">3. Sharing Your Information</h5>
          <p className="details">
            We may share your information with third parties in the following
            circumstances:
          </p>

          <h5>3.1 Event Organizers</h5>
          <p className="detail">
            We share your information with Event Organizers to facilitate event
            management and ticketing services. Event Organizers are responsible
            for their own privacy practices.
          </p>

          <h5>3.2 Service Providers</h5>
          <p className="detail">
            We engage third-party service providers to perform various functions
            on our behalf, such as payment processing, data analysis, and
            marketing assistance. These service providers have access to your
            information as needed to perform their functions and are obligated
            to maintain the confidentiality and security of your information.
          </p>

          <h5>3.3 Legal Requirements</h5>
          <p className="detail">
            We may disclose your information if required to do so by law or in
            response to a subpoena, court order, or other legal process.
          </p>

          <h5>3.4 Business Transfers</h5>
          <p className="detail">
            In the event of a merger, acquisition, or sale of all or a portion
            of our assets, your information may be transferred to the acquiring
            entity.
          </p>
        </section>

        <section className="sectionText">
          <h5 className="title">4. Data Security</h5>
          <p className="detail">
            We implement reasonable security measures to protect your
            information from unauthorized access, use, or disclosure. However,
            no security system is impenetrable, and we cannot guarantee the
            security of your information.
          </p>
        </section>

        <section className="sectionText">
          <h5 className="title">5. Data Retention</h5>
          <p className="detail">
            We retain your information for as long as necessary to fulfill the
            purposes outlined in this Privacy Policy, unless a longer retention
            period is required or permitted by law.
          </p>
        </section>

        <section className="sectionText">
          <h5 className="title">6. Your Rights</h5>
          <p className="detail">
            You have the right to access, correct, update, or delete your
            personal information. You may also object to the processing of your
            information or request the restriction of its processing. To
            exercise these rights, please contact us at [Your Email Address].
          </p>
        </section>

        <section className="sectionText">
          <h5 className="title">7. Children's Privacy</h5>
          <p className="detail">
            Our Platform is not intended for use by children under the age of
            18. We do not knowingly collect personal information from children
            under 18. If we become aware that we have inadvertently collected
            personal information from a child under 18, we will delete such
            information promptly.
          </p>
        </section>

        <section className="sectionText">
          <h5 className="title">8. Changes to This Privacy Policy</h5>
          <p className="detail">
            We may update this Privacy Policy from time to time. We will notify
            you of any material changes by posting the updated Privacy Policy on
            our Platform and updating the "Last Updated" date at the top of this
            page. Your continued use of our Platform after the effective date of
            the revised Privacy Policy constitutes your acceptance of the
            changes.
          </p>
          <p className="detail">
            By using our Platform, you acknowledge that you have read and
            understood this Privacy Policy and agree to its terms.
          </p>
        </section>
      </ComplianceBox>
      <Footer />
    </React.Fragment>
  )
}
