import { styled } from "@mui/material";

export const FooterBox = styled("footer")(({ theme }) => ({
  padding: "1.5rem 0",
  borderTop: "1px solid #E4E4E4",
  background: "#F5F5F5",

  "& .footerHolder": {
    display: "flex",
    alignItems: "center",
  },

  "& .iconHolder": {
    cursor: "pointer",
    order: 1,
  },

  "& .footerLinkGroup": {
    paddingLeft: 0,
    margin: 0,
    display: "flex",
    alignItems: "center",
    listStyle: "none",
    order: 2,

    "& li": {
      fontSize: "1rem",
      fontWeight: 600,
      color: "#0C0C0C",
      margin: "0 2.5rem",
      cursor: "pointer",

      "&:last-child": {
        marginRight: 0,
      },
    },
  },

  "& .socialIconHolder": {
    marginLeft: "auto",
    order: 3,

    "& a": {
      margin: "0 0.5rem",

      "& img": {
        width: "22px",
        height: "22px",
      },

      "&:last-child": {
        marginRight: 0,
      },
    },
  },

  "@media screen and (max-width: 991.9px)": {
    "& .footerHolder": {
      flexWrap: "wrap",
    },

    "& .footerLinkGroup": {
      flexWrap: "wrap",
      marginLeft: "auto",

      "& li": {
        fontSize: "0.875rem",
        margin: "0 2rem",
      },
    },

    "& .socialIconHolder": {
      margin: "2.5rem auto",

      "& a": {
        margin: "0 0.55rem",

        "& img": {
          width: "18px",
          height: "18px",
        },
      },
    },
  },

  "@media screen and (max-width: 767.9px)": {
    "& .iconHolder": {
      margin: "0 auto 0.75rem",
    },

    "& .footerLinkGroup": {
      width: "100%",
      justifyContent: "center",
      marginBottom: "0.75rem",

      "& li": {
        fontSize: "0.75rem",
        margin: "0.75rem 1rem 0",
      },
    },

    "& .socialIconHolder": {
      margin: "0.75rem auto",

      "& a": {
        margin: "0 0.5rem",

        "& img": {
          width: "18px",
          height: "18px",
        },
      },
    },
  },
}));
