import React, { useState, useEffect } from "react"
import { BaseBox } from "./base.style"
import { Navigator } from "../Navigator"
import { Header } from "../Header"
import { Outlet, useNavigate } from "react-router-dom"
import { useTheme } from "@mui/material/styles"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { getUser } from "api"
import { ToastMessage, TOASTER_TYPE, LinearLoader } from "components/lib"
import { PermissionWrapper } from "context"
import { PERMISSION_LISTS } from "helpers/permissions.list"
import { SETTINGS_URL } from "helpers/routes.url"

interface Props {
  children?: React.ReactNode
}

export const Base: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const [width, setWidth] = useState(window.innerWidth)
  const [mobileOpen, setMobileOpen] = useState<boolean>(
    width >= theme.breakpoints.values.lg ? true : false
  )
  const { data, isLoading } = useQuery(["getUser"], getUser, {
    retry: 0,
    // staleTime: Infinity,
    onError: error => {
      if (axios.isAxiosError(error)) {
        if (error.response?.data?.message !== "Unauthorized")
          ToastMessage(TOASTER_TYPE.ERROR, error.response?.data?.message)
      }
    },
  })

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
      setMobileOpen(
        window.innerWidth >= theme.breakpoints.values.lg ? true : false
      )
    }

    // Add event listener for resize
    window.addEventListener("resize", handleResize)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleUpdatePaymentSetting = () => {
    navigate(SETTINGS_URL, {
      state: { tab: 2 },
    })
  }

  return (
    <BaseBox>
      {isLoading && <LinearLoader />}
      <aside className="drawer">
        <Navigator
          drawerWidth={theme.drawerWidth}
          open={mobileOpen}
          onOutsideClick={() => setMobileOpen(false)}
          windowWidth={width}
        />
      </aside>

      <div className="appContent">
        <Header userDetails={data} handleToggle={handleDrawerToggle} />
        <PermissionWrapper
          requiredPermission={PERMISSION_LISTS.MANAGE_SETTING}
          falseReturnType="Element"
        >
          {data &&
            !data?.accountNumber &&
            !data?.bankCode &&
            !data?.accountName && (
              <div className="notificationBar">
                <p className="mb-0">
                  Please update your bank details in payment settings before
                  creating a paid event.
                </p>
                <div onClick={handleUpdatePaymentSetting} className="click">
                  Click here
                </div>
              </div>
            )}
        </PermissionWrapper>

        <main className="mainContent" style={{}}>
          {children}
          <Outlet />
        </main>
      </div>
    </BaseBox>
  )
}
