import { DateTimePicker } from "@mui/x-date-pickers"
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers"
import dayjs, { Dayjs } from "dayjs"
import * as React from "react"

interface IProps {
  value: Dayjs | null | undefined
  handleChange: (arg: any) => void
}

export const FDDateTimePicker: React.FC<IProps> = ({ value, handleChange }) => {
  const [isOpenDate, setIsOpenDate] = React.useState<boolean>(false)

  return (
    <DateTimePicker
      sx={{
        "&.MuiFormControl-root": {
          width: "100%",
        },

        "& .MuiInput-root": {
          width: "100%",
        },

        "& .MuiInput-root:before": {
          content: "none",
        },

        "& .Mui-focused:after": {
          content: "none",
        },

        "& .MuiInput-input": {
          minWidth: "70px",
          padding: "0 0 2px",
        },

        "& input": {
          color: "#424242",
          fontSize: "0.875rem",
          fontWeight: 400,
          padding: "0 !important",
          height: "auto",
        },
      }}
      format="LLL"
      viewRenderers={{
        hours: renderTimeViewClock,
        minutes: renderTimeViewClock,
        seconds: renderTimeViewClock,
      }}
      value={value}
      open={isOpenDate}
      onChange={handleChange}
      onClose={() => setIsOpenDate(false)}
      slotProps={{
        textField: {
          variant: "standard",
          onClick: () => setIsOpenDate(true),
        },
      }}
      slots={{ openPickerButton: () => null }}
      disablePast
    />
  )
}
