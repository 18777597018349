import { DataGrid, GridColDef, DataGridProps } from "@mui/x-data-grid";
import * as React from "react";
import { TableBox } from "./table.style";
import EmptyStateImg from "../../../assets/empty-state.svg";
import { Pagination, Skeleton } from "@mui/material";

interface Props extends DataGridProps {
  rows: any[];
  columns: GridColDef[] | any[] | any;
  height?: string | number | any;
  isCheckboxSelection?: boolean;
  sendSelectedRowToParent?: (arg: any[]) => void;
  loading: boolean;
  pageNumber?: number
  setPageNumber?: any
}

export const DataTable: React.FC<Props> = ({
  rows,
  columns,
  height = "calc(100vh - 170px)",
  isCheckboxSelection = false,
  sendSelectedRowToParent,
  pageNumber,
  setPageNumber,
  loading,
  ...props
}) => {
  const pageSize = 10; // Number of rows per page

  const handlePageChange = (event: any, newPage: number) => {
    setPageNumber(newPage);
  };

  if (loading) {
    // Render Skeleton when loading
    return (
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={[
            { id: 1 },
            { id: 2 },
            { id: 3 },
            // Add more skeleton rows as needed
          ]}
          columns={columns.map((column: any) => ({
            ...column,
            renderCell: () => <Skeleton animation="wave" variant="text" />,
          }))}
          loading
        />
      </div>
    );
  }

  return (
    <TableBox style={{ height: height }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSizeOptions={[10, 20, 30, 40, 50, 100, 200, 300, 500, 1000]}
        paginationMode="server"
        paginationModel={{ page: (pageNumber as number) - 1, pageSize: pageSize }}
        rowCount={props.rowCount}
        hideFooter
        hideFooterPagination
        pagination
        // checkboxSelection={isCheckboxSelection}
      />
      <div className="paginationContainer">
        <Pagination
          count={Math.ceil((props.rowCount as number) / pageSize)}
          page={pageNumber}
          onChange={handlePageChange}
        />
      </div>
    </TableBox>
  );
};
