import {
  StandardTextFieldProps,
  TextField,
  FormHelperText,
  TextFieldProps,
} from "@mui/material";
import React from "react";
import { InputBox } from "./input.styles";
import { useField } from "formik";

interface CustomInputProps extends StandardTextFieldProps {
  name: string;
  isMultiline?: boolean;
}

export const FDInput: React.FC<CustomInputProps> = ({
  fullWidth,
  variant = "outlined",
  name,
  isMultiline,
  ...props
}) => {
  const [field, meta] = useField(name);

  return (
    <InputBox fullWidth={fullWidth}>
      <TextField
        multiline={isMultiline}
        rows={isMultiline ? 3 : 0}
        className="input"
        {...field}
        {...props}
      />

      {meta.touched && meta.error && (
        <FormHelperText className={"feedback"}>{meta.error}</FormHelperText>
      )}
    </InputBox>
  );
};
