import { styled } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import ImgBg from "../../../../assets/bg/header-bg.png";

export const HeroSectionBox = styled("header")(({ theme }) => ({
  background: `url(${ImgBg})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100% 100%",
  backgroundAttachment: "local",
  overflow: "hidden",
  transition: "0.3s all ease-in-out",

  "& .convenerHeaderHeight": {
    height: "1100px",
    padding: "5rem 0",
  },

  "& .vendorHeaderHeight": {
    height: "100%",
    padding: "5rem 0 3rem",
  },

  "& .textHolder": {
    margin: "0 4rem",

    "& .title": {
      color: "#F2BA1D",
      fontSize: "3.5rem",
      fontWeight: 600,
      textAlign: "center",
    },
  },

  "& .imgContainer": {
    position: "relative",
    // overflowX: "hidden",

    "& img": {
      position: "absolute",
    },

    "& .img1": {
      top: 0,
      left: "4.5rem",
    },

    "& .img2": {
      zIndex: 1,
      top: "5rem",
      right: "4.5rem",
    },
  },

  '& .smoothTrans':{
    transition: "0.3s all ease-in-out",
  },

  "& .visible": {
    visibility: "visible",
    opacity: 1,
    transitionDelay: "0.5s",
  },

  "& .hidden": {
    visibility: "hidden",
    opacity: 0,
    transitionDelay: "0s",
  },

  "@media only screen and (min-width: 992px) and (max-width: 1200px)": {
    "& .imgContainer": {
      "& .img1": {
        top: 0,
        left: "-3rem",
      },

      "& .img2": {
        zIndex: 1,
        top: "3rem",
        right: "-3rem",
      },
    },
  },

  "@media screen and (max-width: 991.9px)": {
    "& .convenerHeaderHeight": {
      padding: "4rem 0",
    },

    "& .textHolder": {
      margin: "0 1rem",

      "& .title": {
        fontSize: "2.75rem",
        margin: "0",
      },
    },

    "& .imgContainer": {
      "& .img1": {
        top: 0,
        left: "-2rem",
      },

      "& .img2": {
        zIndex: 1,
        top: "3rem",
        right: "-40%",
      },
    },
  },

  "@media screen and (max-width: 767.9px)": {
    "& .convenerHeaderHeight": {
      height: "850px",
    },

    "& .textHolder": {
      margin: "0",

      "& .title": {
        fontSize: "2.25rem",
      },
    },
  },
}));
