import { BookADemoBox } from "./BookADemo.styles";
import { FDPButton } from "../../../../components";
import { SIGN_UP_URL } from "../../../../helpers/routes.url";

export const BookADemo = () => {
  return (
    <BookADemoBox>
      <div className="container">
        <div className="textHolder">
          <h3 className="title">Made for offices and corporate events</h3>
          <p className="details">
            FronDesq queue management system (QMS) creates better experiences
            for visitors, participants and corporate event management. The
            benefits start from day one with zero wait time and better
            Participant data handling with superior access control features.
            <br />
            With 1-Day Onboarding, We are ready when you are. Book a Demo Now
          </p>

          <div className="btnContainer">
            <FDPButton
              text="Get Started"
              btnVariant="Primary"
              link={SIGN_UP_URL}
              forRouting
            />

            <FDPButton text="Book Demo" btnVariant="Secondary" />
          </div>
        </div>
      </div>
    </BookADemoBox>
  );
};
