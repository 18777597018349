import { styled } from "@mui/material";

export const NavBox = styled("nav")(({ theme }) => ({
  background: "#0C0C0C",
  padding: "1.5rem 0",
  width: "100%",
  overflowX: "hidden",

  "& .navItemContainer": {
    display: "flex",
    alignItems: "center",
  },

  "& .menuContainer": {
    marginLeft: "2.5rem",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },

  "& .menuGroup": {
    margin: 0,
    padding: 0,
    display: "flex",
    alignItems: "center",
    gap: "0 4rem",
    listStyle: "none",

    "& li": {
      "& a": {
        textDecoration: "none",
        color: "#ffffff",
        fontWeight: 600,
        transition: ".3s all ease-in-out",

        "&:hover": {
          color: "#F2BA1D",
        },
      },
    },
  },

  "& .btnContainer": {
    marginLeft: "auto",
    display: "inline-flex",
    alignItems: "center",
    gap: "0 1.25rem",

    "& .convenerText": {
      color: "#ffffff",
      fontSize: "1rem",
      fontWeight: 600,
      cursor: "pointer",
      padding: "0.5rem 1rem",
      borderRadius: "5px",

      "& svg": {},
    },
  },

  "@media screen and (max-width: 991.9px)": {
    overflowX: "hidden",
    width: "100%",

    "& .navItemContainer": {
      justifyContent: "space-between",
    },

    "& .menuContainer": {
      padding: "3rem 2rem",
      marginLeft: "0",
      flexDirection: "column",
      position: "absolute",
      zIndex: 999,
      top: "104px",
      width: "0",
      left: 0,
      right: 0,
      height: "100vh",
      backgroundColor: "#0C0C0C",
      backDropFilter: "blur(10px)",
      opacity: 0,
      transition: "opacity 0.3s ease-in-out, width 0.2s ease-in-out",

      "& .menuGroup, .btnContainer": {
        flexDirection: "column",
        alignItems: "center",
        gap: "2.5rem 0",
        textAlign: "center",
      },

      "& .menuGroup": {
        width: "100%",
        marginBottom: "2.5rem",

        "& li": {
          fontSize: "1.25rem",
          fontWeight: 600,
        },
      },

      "& .btnContainer": {
        marginLeft: 0,
      },
    },

    "& .mobileToggler": {
      "& .convenerText": {
        color: "#ffffff",
        fontSize: "1rem !important",
        fontWeight: "600 !important",

        "& svg": {
          width: "25px",
          marginLeft: "0.25rem",
        },
      },

      "& .hamburgerMenu, .convenerText": {
        padding: "0.5rem 0.75rem",
        borderRadius: "5px",
      },

      "& .hamburgerMenu": {
        "& svg": {
          width: "30px",
          height: "30px",
        },
      },
    },

    "& .show": {
      width: "100%",
      opacity: 1,
    },
  },

  "@media screen and (max-width: 768.9px)": {
    "& .navItemContainer": {
      "& svg": {
        width: "30px",
        height: "30px",
      },
    },

    "& .mobileToggler": {
      "& .convenerText": {
        fontSize: "0.875rem !important",

        "& svg": {
          width: "20px",
        },
      },

      "& .hamburgerMenu, .convenerText": {
        padding: "0.25rem 0.5rem",
      },

      "& .hamburgerMenu": {
        "& svg": {
          width: "25px",
          height: "25px",
        },
      },
    },
  },
}));
