import React from "react";
import { getEventLocationByValue } from "../../../../../AllEvents/components/CreateEvent/MockData/EventData";

interface ShowSelectedLocationProps {
  name: string;
  onClick?: () => void;
  hideIcon?: boolean;
  physicalLocation?: boolean;
  physicalLocationName?: string;
}

export const ShowSelectedLocation = ({
  name,
  onClick,
  hideIcon,
  physicalLocation,
  physicalLocationName,
}: ShowSelectedLocationProps) => {
  const item = getEventLocationByValue(name);
  if (!item) return null;
  return (
    <div className="d-flex align-items-center p-md-2 pointer" onClick={onClick}>
      {/* Check if hideIcon exists and if it does and is true, don't show item.icon */}
      {!hideIcon && item.icon && (
        <div className="me-md-2 me-1">{item.icon as JSX.Element}</div>
      )}
      <span className="itemText">
        {physicalLocation ? physicalLocationName : item.name}
      </span>
    </div>
  );
};
