import * as React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Config } from "../../../config";
import { SIGN_IN_URL } from "../../../helpers/routes.url";

interface Props {
  children?: React.ReactNode;
}
export const AppGuard: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const token = localStorage.getItem("FD_AU_TK");
  // const isLoggedIn = Boolean(token);
  const isLoggedIn = true;

  React.useEffect(() => {
    if (!token) {
      localStorage.clear()
      navigate(SIGN_IN_URL, { replace: true });
      // window.location.href = `${window.location.origin}/sign-in`;
    } else {
      navigate(pathname);
    }
  }, [token, pathname, navigate]);

  return isLoggedIn ? (
    <React.Fragment>
      {children}
      <Outlet />
    </React.Fragment>
  ) : null;
};
