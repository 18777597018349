import { styled } from "@mui/material";

export const FeaturesBox = styled("section")(({ theme }) => ({
  padding: "4rem 0",
  backgroundImage: "",
  borderTop: "1px solid #C9C9C9",

  "& .textHolder": {
    margin: "0 4rem 0 0",

    "& .title": {
      color: theme.palette.secondary.main,
      fontWeight: 600,
      fontSize: "2rem",
      marginBottom: "1.5rem",
    },

    "& .subTitle": {
      color: theme.palette.primary.main,
      fontWeight: 700,
      fontSize: "1.25rem",
    },

    "& .details": {
      color: "#424242",
      fontWeight: 400,
      margin: "1rem 0 0",
    },

    "& .btnContainer": {
      display: "flex",
      gap: "0 1.25rem",
      alignItems: "center",
      justifyContent: "center",
    },
  },

  "@media screen and (max-width: 1199.9px)": {
    padding: "4rem 0",

    "& .textHolder": {
      margin: "0 3rem 0 0",

      "& .title": {
        fontSize: "1.85rem",
      },

      "& .subTitle": {
        fontSize: "1.2rem",
      },
    },
  },

  "@media screen and (max-width: 767.9px)": {
    padding: "4rem 0",

    "& .textHolder": {
      margin: 0,

      "& .title": {
        fontSize: "1.75rem",
        marginBottom: "0.5rem",
      },

      "& .subTitle": {
        fontSize: "1.15rem",
      },

      "& .details": {
        fontSize: "0.95rem",
      },
    },
  },
}));
