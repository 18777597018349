import { GetStartedBox } from "./GetStarted.styles";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import * as React from "react";
import { Swiper as SwiperType } from "swiper";
import Step1Img from "../../../../assets/images/v-step-1.svg";
import Step2Img from "../../../../assets/images/v-step-2.svg";
import Step3Img from "../../../../assets/images/v-step-3.svg";
import { ArrowLeftIcon, ArrowRightIcon } from "../../../../assets/svg";
import { IconButton } from "@mui/material";

export const VendorGetStarted = () => {
  const sliderRef = React.useRef<SwiperType>(null);

  const handleNext = React.useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const handleBack = React.useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  return (
    <GetStartedBox>
      <div className="container">
        <div className="contentContainer">
          <h3 className="title">Get started in 3 steps</h3>

          <Swiper
            ref={sliderRef}
            // slidesPerView={1}
            // spaceBetween={17}
            className="mySwiper wwd_my_swiper"
            // modules={[Autoplay, Navigation]}
            // navigation={false}
            // loop={true}
            // autoplay={{
            //   delay: 2500,
            //   disableOnInteraction: false,
            // }}
          >
            <SwiperSlide>
              <div className="vendorFlowContainer">
                <img src={Step1Img} className="img-fluid" />
                <h4 className="header">Create your portfolio</h4>
                <p className="details">
                  Showcase your services, portfolio, and business information.
                  Customize your page with your logo, brand colors, and an
                  engaging description to leave a lasting impression.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="vendorFlowContainer">
                <img src={Step2Img} className="img-fluid" />
                <h4 className="header">Get noticed by top clients</h4>
                <p className="details">
                  Highlight previous events you've successfully executed to give
                  potential clients a glimpse of your expertise, view and select
                  the services they need directly from your portfolio
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="vendorFlowContainer">
                <img src={Step3Img} className="img-fluid" />
                <h4 className="header">
                  Manage inquiries, bookings &amp; reviews
                </h4>
                <p className="details">
                  Keep track of your upcoming bookings, availability, and
                  appointments. Build trust and credibility by showcasing
                  positive reviews and testimonials from your satisfied clients.
                </p>
              </div>
            </SwiperSlide>

            <div className="navBar">
              <IconButton onClick={handleBack}>
                <ArrowLeftIcon />
              </IconButton>

              <IconButton onClick={handleNext}>
                <ArrowRightIcon />
              </IconButton>
            </div>
          </Swiper>
        </div>
      </div>
    </GetStartedBox>
  );
};
