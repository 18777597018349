import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import { DigitalClock } from "@mui/x-date-pickers/DigitalClock";
import { PopoverComponent } from "../../";
import { Box } from "@mui/material";

interface IProps {
  value: Date | Dayjs | string | number;
  handleChange: (arg: any) => void;
}

export const FDTimePicker: React.FC<IProps> = ({ value, handleChange }) => {
  // value = new Date(value as any).toISOString();

  return (
    <>
      <PopoverComponent
        title={
          <Box
            sx={{
              color: "#424242",
              fontSize: "0.875rem",
              fontWeight: 400,
            }}
          >
            {dayjs(value).format("hh:mma")}
          </Box>
        }
        children={
          <DigitalClock
            timeStep={30}
            // defaultValue={dayjs(value)}
            value={dayjs(value)}
            onChange={handleChange}
          />
        }
      />
    </>
  );
};
