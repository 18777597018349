// import CloseIcon from "@mui/icons-material/Close"
import { Box } from "@mui/material"
import AppBar from "@mui/material/AppBar"
import Dialog, { DialogProps } from "@mui/material/Dialog"
import IconButton from "@mui/material/IconButton"
import Slide from "@mui/material/Slide"
import Toolbar from "@mui/material/Toolbar"
import { TransitionProps } from "@mui/material/transitions"
import * as React from "react"
import { Theme, Typography } from "@mui/material"

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface Props {
  title?: string
  details?: string
  dialogProps: DialogProps
  children?: React.ReactNode
}
export const CenterDialog: React.FC<Props> = ({
  title,
  details,
  dialogProps,
  children,
}) => {
  return (
    <Dialog
      {...dialogProps}
      TransitionComponent={Transition}
      maxWidth={dialogProps?.maxWidth ?? "xs"}
    >
      <Box
        sx={{
          px: 4,
          pt: 4,
          position: "relative",
          overflowY: "hidden",
        }}
      >
        <Box>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-inline-flex gap-3 align-items-center">
              <Typography
                sx={{
                  flex: 1,
                  color: "#001935",
                  fontSize: "1.3rem",
                }}
                variant="h5"
                fontWeight={600}
              >
                {title}
              </Typography>
            </div>

            {/* Close Button */}
            <IconButton
              edge="start"
              color="inherit"
              onClick={e => {
                dialogProps?.onClose && dialogProps.onClose(e, "escapeKeyDown")
              }}
              aria-label="close"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.0007 5.5865L11.9504 0.636719L13.3646 2.05093L8.4149 7.0007L13.3646 11.9504L11.9504 13.3646L7.0007 8.4149L2.05093 13.3646L0.636719 11.9504L5.5865 7.0007L0.636719 2.05093L2.05093 0.636719L7.0007 5.5865Z"
                  fill="#C9C9C9"
                />
              </svg>
            </IconButton>
          </div>
          <Typography
            sx={{ color: "#424242", fontSize: "1rem" }}
            variant="h6"
            fontWeight={400}
          >
            {details}
          </Typography>
        </Box>

        <Box
          sx={{
            overflowY: "auto",
            maxHeight: "calc(100vh - 100px)",
          }}
        >
          {children}
        </Box>
      </Box>
    </Dialog>
  )
}
