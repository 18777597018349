import { ButtonProps, CircularProgress } from "@mui/material"
import React from "react"
//   import { RiCustomerService2Line as CustomerServiceIcon } from "react-icons/ri"
//   import { Ripple } from "../../styledComponents/animatedStyles"
import { useNavigate } from "react-router-dom"
import { ButtonBox } from "./Buttons.styles"

interface FDPButtonProps {
  text: string | React.ReactNode
  loading?: boolean
  isDisabled?: boolean
  buttonProps?: ButtonProps
  click?: () => void | any | undefined
  type?: "button" | "submit" | "reset"
  btnVariant?:
    | "Primary"
    | "PrimaryOutline"
    | "Secondary"
    | "Danger"
    | "DarkOutline"
  forRouting?: boolean
  link?: string
}

const primaryVariant = {
  background: (theme: any) => theme.palette.primary.main,
  color: "#ffffff",

  "&:disabled": {
    background: "rgba(11, 97, 224, .5) !important",
    color: "#ffffff",
  },

  "&:hover": {
    background: (theme: any) => theme.palette.primary.light,
    opacity: 0.85
  },
}

const secondaryVariant = {
  background: "#ffffff",
  color: (theme: any) => theme.palette.primary.main,

  // "&:hover": {
  //   background: (theme: any) => theme.palette.primary.light,
  // },
}

const primaryOutlineVariant = {
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: (theme: any) => theme.palette.primary.main,
  background: "transparent",
  color: (theme: any) => theme.palette.primary.main,

  "&:disabled": {
    borderColor: "rgba(0, 0, 0, 0.26) !important",
  },
}

const darkOutlineVariant = {
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: (theme: any) => theme.palette.secondary.main,
  background: "transparent",
  color: (theme: any) => theme.palette.secondary.main,
}

const dangerVariant = {
  background: "#F71C1C",
  color: "#ffffff",

  "&:disabled": {
    backgroundColor: "rgba(247, 28, 28, .5) !important",
  },

  "&:hover": {
    backgroundColor: "rgba(247, 28, 28, .8) !important",
  },
}

export const FDPButton: React.FC<FDPButtonProps> = ({
  text,
  loading,
  buttonProps,
  click,
  type,
  btnVariant = "Primary",
  forRouting = false,
  link = "",
  isDisabled,
}) => {
  const navigate = useNavigate()

  const handleClick = () => {
    if (forRouting) {
      navigate(link)
    } else {
      click?.()
    }
  }

  return (
    <ButtonBox
      sx={
        btnVariant === "Primary"
          ? primaryVariant
          : btnVariant === "Secondary"
          ? secondaryVariant
          : btnVariant === "PrimaryOutline"
          ? primaryOutlineVariant
          : btnVariant === "Danger"
          ? dangerVariant
          : btnVariant === "DarkOutline"
          ? darkOutlineVariant
          : undefined
      }
      onClick={handleClick}
      type={type}
      disabled={loading || isDisabled}
      {...buttonProps}
    >
      <>{text}</>
      {loading && (
        <CircularProgress
          sx={{
            marginLeft: "0.75rem",
            color: "#ffffff",
            width: "1.5rem !important",
            height: "1.5rem !important",
          }}
        />
      )}
    </ButtonBox>
  )
}
