import { FormControl, styled } from "@mui/material"

export const InputBox = styled(FormControl)(({ theme }) => ({
  margin: "0.5rem 0",
  "& .input": {
    "& .MuiOutlinedInput-input": {
      padding: "1rem 1.5rem",
    },

    "& .MuiInputBase-inputMultiline.MuiOutlinedInput-inputMultiline": {
      padding: "0.5rem",
    },

    "& .MuiInputLabel-outlined": {
      fontSize: "0.865rem",
    },

    "& .MuiFormLabel-root.Mui-focused": {
      color: theme.palette.text,
    },

    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(121, 121, 121, 0.87)",
      borderWidth: 1,
    },

    "& .PrivateNotchedOutline-root-253.MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(121, 121, 121, 0.87)",
      borderWidth: 1,
    },

    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderWidth: 1,
      borderColor: "#f44336",
    },
  },
  "& .feedback": {
    color: "#f44336",
    paddingBottom: theme.spacing(1),

    "& span": {
      fontSize: "0.85rem",
    },
  },
}))
