import { DevelopmentConfig } from "./config.development"
import { ProductionConfig } from "./config.production"

const env = process.env
const isProd = env.REACT_APP_REACT_ENV === "prod";
// const isProd = env.NODE_ENV === "production";
console.log({
  node: env.NODE_ENV,
  REACT_ENV: env.REACT_APP_REACT_ENV,
  isProd,
  baseDev: env.REACT_APP_API_BASE_URL_DEV,
  baseProd: env.REACT_APP_API_BASE_URL_PROD,
});

// Use the appropriate configuration based on the environment
export const Config = isProd ? ProductionConfig : DevelopmentConfig

// Now, you can use Config to access your configuration settings
