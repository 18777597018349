import { FeaturesBox } from "./Features.styles";
import AppointmentMgtImg from "../../../../assets/images/appointment-mgt-mu.svg";
import QueueMgtImg from "../../../../assets/images/queue-mgt-mu.svg";
import EventMgtImg from "../../../../assets/images/event-mgt-mu.svg";
import PrivacySecurityImg from "../../../../assets/images/privacy-security-mu.svg";
import DataDrivenMgtImg from "../../../../assets/images/data-driven-mgt.svg";

export const Features = () => {
  return (
    <FeaturesBox id="featureSection">
      <div className="container">
        {/* <div className="row mb-4">
          <div className="col-lg-6 d-flex align-items-center order-2 order-lg-1">
            <div className="textHolder">
              <h4 className="title">Appointment Management</h4>
              <h6 className="subTitle">Eliminate wait time</h6>

              <p className="details">
                With FronDesq’s unique online appointment booking and queue
                management system, your visitors and customers don’t have to
                wait in line. Give your customers time back and enjoy a
                predictable day with powerful data analytics.
                <br /> <br />
                Decide what your day looks like with intuitive appointment
                schedules that spread customer bookings throughout the day. With
                us, appointment scheduling is easy.
              </p>
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center order-1 order-lg-2">
            <img
              src={AppointmentMgtImg}
              className="img-fluid"
              alt="Appointment management with Frondesq"
            />
          </div>
        </div> */}

        <div className="row mb-4 py-4 py-lg-0">
          <div className="col-lg-6 d-flex align-items-center">
            <img
              src={QueueMgtImg}
              className="img-fluid"
              alt="Queue Management for Corporate Events using Frondesq"
            />
          </div>

          <div className="col-lg-6 d-flex align-items-center">
            <div className="textHolder">
              <h4 className="title">Queue Management for Corporate Events</h4>
              <h6 className="subTitle">
                Eliminate manual Participant check-in
              </h6>

              <p className="details">
                There’s nothing more frustrating than manual paper check-in of
                large crowd at your event, FronDesq’s unique check-in system
                allows you to easily search registered participants and
                seamlessly check them in within 30seconds.
              </p>
            </div>
          </div>
        </div>

        <div className="row mb-4 py-4 py-lg-0">
          <div className="col-lg-6 d-flex align-items-center order-2 order-lg-1">
            <div className="textHolder">
              <h4 className="title"> Corporate Event Management</h4>
              <h6 className="subTitle">Create your event in style</h6>

              <p className="details">
                Whether free or paid event, FronDesq is equipped with event
                registration forms that you can customize to your needs. The
                forms can be embedded into your website or shared with
                participants. More interestingly; all registered participants
                appear on the participant’s table with check-In button for a
                more seamless experience on the day of event.
              </p>
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center order-1 order-lg-2">
            <img
              src={EventMgtImg}
              className="img-fluid"
              alt="Corporate Event Management using Frondesq"
            />
          </div>
        </div>

        <div className="row mb-4 py-4 py-lg-0">
          <div className="col-lg-6 d-flex align-items-center">
            <img
              src={PrivacySecurityImg}
              className="img-fluid"
              alt="Privacy and Security while using Frondesq"
            />
          </div>

          <div className="col-lg-6 d-flex align-items-center">
            <div className="textHolder">
              <h4 className="title">Privacy &amp; Security</h4>
              <h6 className="subTitle">
                Secure data privacy for your Participants
              </h6>

              <p className="details">
                FronDesq is built with data privacy in mind; it is equipped with
                access and permission controls that prevent unauthorized access
                to certain levels of Administrators. With this, you can be rest
                assured that your participants won’t be getting unsolicited
                emails from bad actors any time soon.
              </p>
            </div>
          </div>
        </div>

        <div className="row mb-4 pt-4 pt-lg-0">
          <div className="col-lg-6 d-flex align-items-center order-2 order-lg-1">
            <div className="textHolder">
              <h4 className="title">Data Driven Decisions</h4>
              <h6 className="subTitle">Optimize your operations</h6>

              <p className="details">
                With data on every touchpoint, our queue management system makes
                it easy to optimize your operations. Know which days are busy
                and when you can send staff home to their families early.
              </p>
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center order-1 order-lg-2">
            <img
              src={DataDrivenMgtImg}
              className="img-fluid"
              alt="Data Driven Decisions when using Frondesq"
            />
          </div>
        </div>
      </div>
    </FeaturesBox>
  );
};
