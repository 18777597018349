import axiosInstance from "./instance";

export const createDiscount = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: `discount`,
      method: "POST",
      data: data,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const updateDiscount = async (data: any) => {
  const {id, ...payload} = data
  
  try {
    const response = await axiosInstance({
      url: `discount/${data.id}`,
      method: "PATCH",
      data: payload,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteDiscount = async (data: any) => {
  
  try {
    const response = await axiosInstance({
      url: `discount/${data.id}`,
      method: "DELETE",
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const getDiscountOnEventByTier = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: `discount/event/${data.eventId}?tierId=${data.tierId}`,
      method: "GET",
    });

    return response;
  } catch (error) {
    throw error;
  }
};
