import { Drawer, Box, styled } from "@mui/material";

export const NavigatorBox = styled(Box)(({ theme }) => ({
  height: "100%",
  display: "block",
  position: "fixed",
  transition: ".5s all ease-in-out",
  zIndex: 1000,
  borderRight: "1px solid #E4E4E4",

  "& .navBg": {
    background: "#ffffff",
    height: "100%",
  },

  "& .MuiDrawer-paper": {
    width: `${theme.drawerWidth}px`,
  },

  "& .MuiList-root": {
    overflowY: "auto",
    height: "100%",
    display: "block",
    position: "relative",
  },

  "& .logoSection": {
    margin: "1.5rem 0 2rem 1.75rem",
  },

  "& .MuiListItem-root": {
    margin: "0 0 0.5rem 0",
    padding: "0 1.25rem 0 1.25rem",
  },

  "& .divider": {
    margin: "0 0 0.5rem",
    background: "#E4E4E4",
  },

  // Multiple module drop down
  "& .itemList": {
    padding: "1rem",
    color: "#787878",
    fontSize: "0.95rem",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    transition: "all .3s ease",

    "& .icon": {
      marginRight: "1rem",

      "& path": {
        transition: "all .3s ease",
        fill: "#787878",
      },
    },

    "& .chervonDown": {
      "& svg path": {
        fill: "#787878",
      },
    },

    "&:hover": {
      color: "#0B61DD",
      background: "#F4F9FF",

      "& .icon": {
        "& path": {
          fill: " #0B61DD",
        },
      },
    },
  },

  "& .itemTitleList": {
    cursor: "auto",

    "&:hover": {
      color: "#787878",
      background: "unset",

      "& .icon": {
        "& path": {
          fill: "unset",
        },
      },
    },
  },

  "& .singleItemTitleList": {
    padding: "0.5rem 2rem 0.5rem 2.5rem",
    color: "#787878",
    fontWeight: 500,
    borderRadius: "8px",
    margin: "0.5rem 0",
    display: "flex",
    alignItems: "center",
    gap: "0 0.5rem",
    cursor: "pointer",

    "&:last-child": {
      marginBottom: 0,
    },

    "&:hover": {
      background: "#F5F5F5",
    },
  },

  "& .singleActiveItem": {
    background: "#F5F5F5",
  },

  "& .activeLink": {
    background: "#F4F9FF",
    borderRadius: "8px",
    color: "#0B61DD",
    fontWeight: 600,

    "& .icon": {
      "& path": {
        fill: " #0B61DD",
      },
    },

    "& .chervonDown": {
      "& svg path": {
        fill: theme.palette.primary.main,
      },
    },
  },

  //media queries
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));
