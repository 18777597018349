import * as React from "react"
import { ComplianceBox } from "./compliance.styles"
import { Footer } from "pages/Website/components"
import { ScrollToTop } from "components"

export const RefundPolicy = () => {
  return (
    <React.Fragment>
      <ScrollToTop />
      <ComplianceBox className="container">
        <header className="headerSection">
          <h3 className="title">Privacy Policy</h3>
          <p className="detail">Effective Date: 31/07/2024</p>
        </header>

        <section className="sectionText">
          <p className="detail">
            This Refund Policy (the “Policy”) outlines the conditions under
            which you, as an Event Attendee, may be eligible for a refund for
            tickets purchased via Frondesq’s website or mobile application (the
            “Platform”).
          </p>
          <p className="detail">
            By accepting this Policy, you confirm that you have carefully read
            and understood the terms outlined below.
          </p>
          <p className="detail">
            <b>Platform Role:</b> Frondesq provides an automated platform that
            allows conveners (“Event Organisers”) to sell tickets directly to
            their attendees. Frondesq does not organize these events or
            guarantee their quality.
          </p>
          <p className="detail">
            <b>Liability:</b> Frondesq’s involvement is limited to facilitating
            the sale of tickets. Event Organisers are responsible for their own
            sales terms and handling any issues that arise. Consequently,
            Frondesq is not liable for any losses or damages resulting from
            event cancellations or any problems experienced at events purchased
            through our Platform.
          </p>
          <p className="detail">
            <b>Processing Fee:</b> Frondesq charges a one-time, non-refundable
            processing fee (“Frondesq Fee”) for each ticket purchased. The
            ticket price and applicable Frondesq Fee will be clearly stated at
            the time of purchase.
          </p>
          <p className="detail">
            <b>Event Dissatisfaction:</b> If you are unhappy with the
            cancellation or quality of an event, Frondesq will not be
            responsible for any related damages, whether direct or indirect,
            incidental, consequential, special, punitive, or exemplary.
          </p>
          <p className="detail">
            <b>Refund Requests:</b> If you seek a refund due to event
            cancellation or dissatisfaction, you must contact the Event
            Organisers directly. You are entitled to a full refund of the ticket
            price from the Event Organisers, but the Frondesq Fee is
            non-refundable.
          </p>
          <p className="detail">
            <b>Contacting Organisers:</b> For any issues regarding event
            cancellations or quality, you agree to directly engage with the
            Event Organisers to resolve your refund requests.
          </p>

          <p className="detail">
            For further details on handling refund requests, please reach out to
            Frondesq Support at hello@oneidtech.com or contact 09078899843.
          </p>
        </section>
      </ComplianceBox>
      <Footer />
    </React.Fragment>
  )
}
