import { FDPButton } from "..";
import { EventNotFoundBox } from "./styles";
import EventNotFoundImg from "../../../assets/images/event-not-found.svg";
import { useNavigate } from "react-router-dom";

export const EventNotFound = () => {
  const navigate = useNavigate();
  return (
    <EventNotFoundBox>
      <div className="textHolder">
        <img
          src={EventNotFoundImg}
          alt="Event not found"
          className="img-fluid"
        />
        <h3 className="title">Page not found</h3>
        <p className="details">
          The URL link for the event cannot be found. Please confirm that the
          link is correct and try again
        </p>
        <FDPButton
          text="Go home"
          btnVariant="Primary"
          click={() => navigate("/")}
        />
      </div>
    </EventNotFoundBox>
  );
};
