import { Button, styled } from "@mui/material";

export const ButtonBox = styled(Button)(({ theme }) => ({
  fontWeight: 600,
  height: "51px",
  fontSize: "0.85rem",
  padding: "0.5rem 2rem",
  transition: "0.3s all ease-in-out",
  borderRadius: "8px",
  textTransform: "capitalize",

  "& .primaryBtn": {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },

  "& .secondaryBtn": {
    background: theme.palette.primary.contrastText,
  },

  "& .Mui-disabled": {
    background: "#A7C9FB !important",
    color: "#ffffff !important",
  },


}));
