import { styled } from "@mui/material"

export const BaseBox = styled("div")(({ theme }) => ({
  "& .root": {
    display: "block",
    background: "#FAFAFB",
  },

  "& .drawer": {
    zIndex: 1000,
    [theme.breakpoints.up("sm")]: {
      flexShrink: 0,
      width: `${theme.drawerWidth}px`,
    },
  },

  "& .appContent": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    background: "#FAFAFB",
  },

  "& .notificationBar": {
    backgroundColor: "#CEF7F7",
    padding: "1rem",
    display: "flex",
    alignItems: "center",
    gap: "1.5rem 1rem",
    justifyContent: "center",

    "& p": {
      color: "#424242",
      fontWeight: 600,
    },

    "& .click": {
      color: theme.palette.primary.main,
      textDecoration: "underline",
      cursor: "pointer",
      fontWeight: 600,
    },

    [theme.breakpoints.up("lg")]: {
      marginLeft: `${theme.drawerWidth}px`,
    },
  },

  "& .mainContent": {
    flex: 1,
    padding: "1rem",
    maxHeight: `calc(100vh - 75px)`,
    height: "100%",
    overflowY: "auto",

    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#8080802e",
      borderRadius: "4px",
    },

    "@media (min-width: 1850px)": {
      maxWidth: "1350px !important",
      marginLeft: "0 !important",
      margin: "0 auto !important",
      width: "100%",
    },

    [theme.breakpoints.up("lg")]: {
      marginLeft: `${theme.drawerWidth}px`,
    },

    [theme.breakpoints.down("xs")]: {
      padding: "2rem 1rem",
    },
    [theme.breakpoints.down("md")]: {
      "& .mainContent": {
        padding: "1rem",
      },
    },
  },

  "@media screen and (max-width: 767.9px)": {
    "& .mainContent": {
      padding: "1.5rem 1rem",
    },
  },
}))
