// import CloseIcon from "@mui/icons-material/Close"
import { Box, Typography } from "@mui/material"
import Dialog, { DialogProps } from "@mui/material/Dialog"
import IconButton from "@mui/material/IconButton"
import Slide from "@mui/material/Slide"
import { TransitionProps } from "@mui/material/transitions"
import * as React from "react"

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />
})

interface Props {
  title: string
  details?: string
  dialogProps: DialogProps
  children?: React.ReactNode
  backButtonClick?: () => void | null | undefined
}
export const StaticSideDialog: React.FC<Props> = ({
  title,
  details,
  dialogProps,
  children,
  backButtonClick,
}) => {
  return (
    <Dialog
      {...dialogProps}
      sx={{
        "& .MuiDialog-container": {
          justifyContent: "flex-end",
          alignItems: "flex-start",
        },
      }}
      PaperProps={{
        sx: {
          m: 0,
          top: 0,
          right: 0,
          maxHeight: "100vh",
          height: "100%",
          borderRadius: 0,
        },
      }}
      TransitionComponent={Transition}
      fullWidth={dialogProps?.fullWidth ?? true}
      maxWidth={dialogProps?.maxWidth ?? "sm"}
    >
      <Box
        sx={{
          px: 4,
          pt: 4,
          position: "relative",
          overflowY: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            // maxHeight: "75px",
            backgroundColor: "#ffffff",
            backdropFilter: "blur(15px)",
            paddingBottom: "1rem",
          }}
        >
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-inline-flex gap-3 align-items-center">
              {/* Back Button */}
              {backButtonClick && (
                <IconButton
                  onClick={backButtonClick}
                  edge="start"
                  color="inherit"
                  aria-label="back"
                >
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.82843 7.4999H16V9.4999H3.82843L9.1924 14.8638L7.7782 16.278L0 8.4999L7.7782 0.72168L9.1924 2.13589L3.82843 7.4999Z"
                      fill="#001935"
                    />
                  </svg>
                </IconButton>
              )}

              <Typography
                sx={{
                  flex: 1,
                  color: "#001935",
                  fontSize: "1.3rem",
                }}
                variant="h5"
                fontWeight={600}
              >
                {title}
              </Typography>
            </div>

            {/* Close Button */}
            <IconButton
              edge="start"
              color="inherit"
              onClick={e => {
                dialogProps?.onClose && dialogProps.onClose(e, "backdropClick")
              }}
              aria-label="close"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.0007 5.5865L11.9504 0.636719L13.3646 2.05093L8.4149 7.0007L13.3646 11.9504L11.9504 13.3646L7.0007 8.4149L2.05093 13.3646L0.636719 11.9504L5.5865 7.0007L0.636719 2.05093L2.05093 0.636719L7.0007 5.5865Z"
                  fill="#C9C9C9"
                />
              </svg>
            </IconButton>
          </div>
          <Typography
            sx={{ color: "#424242", fontSize: "1rem" }}
            variant="h6"
            fontWeight={400}
          >
            {details}
          </Typography>
        </Box>

        <Box
          sx={{
            overflowY: "auto",
            maxHeight: "calc(100vh - 100px)",
            paddingRight: "10px",
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#8080802e",
              borderRadius: "4px",
            },
          }}
        >
          {children}
        </Box>
      </Box>
    </Dialog>
  )
}
