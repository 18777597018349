import axiosInstance from "./instance";
import axios from "axios";
import { Config } from "../config";

const PAYSTACK_BASE_URL = "https://api.paystack.co";
const CSC_BASE_URL = "https://api.countrystatecity.in/v1";

const paystackConfig = {
  headers: {
    Authorization: `Bearer ${Config.PAYSTACK_SECRET_KEY}`, // Replace with your actual access token
  },
};

const cscConfig = {
  headers: {
    "X-CSCAPI-KEY": Config.CSC_API_KEY,
  },
};

export const getPresignedUrl = async (data: any) => {
  try {
    const response = await axiosInstance({
      url: "uploader/presigned-url",
      method: "POST",
      data: data,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllBanks = async (data: any) => {
  try {
    const response = await axios.get(
      `
    ${PAYSTACK_BASE_URL}/bank
    `,
      paystackConfig
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyAccountNumber = async (data: any) => {
  console.log(data);
  const paramData = {
    account_number: data.queryKey[1].accountNumber,
    bank_code: data.queryKey[1].bankCode,
  };

  const params = new URLSearchParams({
    ...paramData,
  });

  try {
    const response = await axios.get(
      `
    ${PAYSTACK_BASE_URL}/bank/resolve?${params}
    `,
      paystackConfig
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCountries = async (data: any) => {
  try {
    const response = await axios.get(
      `
    ${CSC_BASE_URL}/countries
    `,
      cscConfig
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSelectedCountryStates = async (data: any) => {
  
  try {
    const response = await axios.get(
      `
    ${CSC_BASE_URL}/countries/${data.queryKey[1].countryCode}/states
    `,
      cscConfig
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSelectedCountryAndStateCities = async (data: any) => {
  try {
    const response = await axios.get(
      `
    ${CSC_BASE_URL}/countries/${data.queryKey[1].countryCode}/states/${data.queryKey[1].stateCode}/cities
    `,
      cscConfig
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
