import React, { lazy, Suspense } from "react"
import { Base, AppGuard } from "../components"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"
import {
  HOME_URL,
  PRICING_URL,
  SIGN_IN_URL,
  SIGN_UP_URL,
  FORGET_PASSWORD_URL,
  CHANGE_PASSWORD_URL,
  PASSWORD_RESET_URL,
  VERIFY_ACCOUNT_URL,
  DASHBOARD_URL,
  EVENTS_URL,
  EVENTS_PARTICIPANTS_URL,
  EVENTS_ARCHIVE_URL,
  SETTINGS_URL,
  EVENT_REGISTRATION_URL,
  VENDORS_DIRECTORY_URL,
  MY_VENDORS_URL,
  EVENTS_ANALYTICS_URL,
  EVENT_FEEDBACK_URL,
  PRIVACY_POLICY_URL,
  REFUND_POLICY_URL,
  TERMS_OF_USE_URL,
  SELF_CHECK_IN_URL,
} from "../helpers/routes.url"
import PermissionProvider from "../context/PermissionProvider/PermissionProvider"
import { FullPageLoader } from "../components"
import { PrivacyPolicy } from "pages/Compliance/PrivacyPolicy"
import { RefundPolicy } from "pages/Compliance/RefundPolicy"
import { TermsOfUse } from "pages/Compliance/TermsOfUse"
import { SelfCheckIn } from "pages/SelfCheckIn/SelfCheckIn"

// Lazy loaded pagees
const Home = lazy(() => import("../pages/Website/Home/Home"))
const Pricing = lazy(() => import("../pages/Website/Pricing/Pricing"))
const RegistrationPage = lazy(
  () => import("../pages/Registration/Registration")
)
const FeedbackPage = lazy(() => import("../pages/Feedback/Feedback"))
const SignUp = lazy(() => import("../pages/Auth/SignUp/SignUp"))
const SignIn = lazy(() => import("../pages/Auth/SignIn/SignIn"))
const ForgetPassword = lazy(
  () => import("../pages/Auth/ForgetPassword/ForgetPassword")
)
const ResetPassword = lazy(
  () => import("../pages/Auth/ResetPassword/ResetPassword")
)
const VerifyAccount = lazy(
  () => import("../pages/Auth/VerifyAccount/VerifyAccount")
)

const DashboardOverview = lazy(() => import("../pages/Dashboard/Dashboard"))
const AllEvents = lazy(() => import("../pages/Events/AllEvents/AllEvents"))
const ManageEvents = lazy(
  () => import("../pages/Events/ManageEvents/ManageEvents")
)
const EventAnalytics = lazy(
  () => import("../pages/Events/EventAnalytics/EventAnalytics")
)
const Participants = lazy(
  () => import("../pages/Events/Participants/Participants")
)
const ParticipantView = lazy(
  () => import("../pages/Events/Participants/ParticipantView/ParticipantView")
)
const ArchivedParticipants = lazy(
  () => import("../pages/Events/Participants/Archived/Archived")
)
const Settings = lazy(() => import("../pages/Settings/Account"))
const MyVendor = lazy(() => import("../pages/Vendors/MyVendor/MyVendor"))
const VendorDirectory = lazy(
  () => import("../pages/Vendors/VendorDirectory/VendorDirectory")
)

export const MainRoutes = () => {
  return (
    <Router>
      <Routes>
        {/* Webiste Route */}
        <Route
          path={HOME_URL}
          element={
            <Suspense fallback={<FullPageLoader />}>
              <Home />
            </Suspense>
          }
        />

        {/* <Route path=":feature" element={<Home />} /> */}
        <Route
          path={PRICING_URL}
          element={
            <Suspense fallback={<FullPageLoader />}>
              <Pricing />
            </Suspense>
          }
        />

        {/* Event reg */}
        <Route
          path={EVENT_REGISTRATION_URL + "/:slug"}
          element={
            <Suspense fallback={<FullPageLoader />}>
              <RegistrationPage />
            </Suspense>
          }
        />

        {/* Self check in */}
        <Route
          path={`${SELF_CHECK_IN_URL}/:slug`}
          element={
            <Suspense fallback={<FullPageLoader />}>
              <SelfCheckIn />
            </Suspense>
          }
        />

        {/* Event Feedback */}
        <Route
          path={`${EVENT_FEEDBACK_URL}/:slug/review`}
          element={
            <Suspense fallback={<FullPageLoader />}>
              <FeedbackPage />
            </Suspense>
          }
        />

        {/* Auth Route */}
        <Route
          path={SIGN_UP_URL}
          element={
            <Suspense fallback={<FullPageLoader />}>
              <SignUp />
            </Suspense>
          }
        />
        <Route
          path={SIGN_IN_URL}
          element={
            <Suspense fallback={<FullPageLoader />}>
              <SignIn />
            </Suspense>
          }
        />
        <Route
          path={FORGET_PASSWORD_URL}
          element={
            <Suspense fallback={<FullPageLoader />}>
              <ForgetPassword />
            </Suspense>
          }
        />
        <Route
          path={PASSWORD_RESET_URL}
          element={
            <Suspense fallback={<FullPageLoader />}>
              <ResetPassword />
            </Suspense>
          }
        />
        <Route
          path={CHANGE_PASSWORD_URL}
          element={
            <Suspense fallback={<FullPageLoader />}>
              <ResetPassword />
            </Suspense>
          }
        />
        <Route
          path={VERIFY_ACCOUNT_URL}
          element={
            <Suspense fallback={<FullPageLoader />}>
              <VerifyAccount />
            </Suspense>
          }
        />
        <Route
          path={PRIVACY_POLICY_URL}
          element={
            <Suspense fallback={<FullPageLoader />}>
              <PrivacyPolicy />
            </Suspense>
          }
        />
        <Route
          path={REFUND_POLICY_URL}
          element={
            <Suspense fallback={<FullPageLoader />}>
              <RefundPolicy />
            </Suspense>
          }
        />
        <Route
          path={TERMS_OF_USE_URL}
          element={
            <Suspense fallback={<FullPageLoader />}>
              <TermsOfUse />
            </Suspense>
          }
        />

        {/* App Route */}

        <Route path="app/" element={<AppGuard />}>
          <Route element={<Base />}>
            <React.Fragment>
              <Route
                index
                element={
                  <Suspense fallback={<FullPageLoader />}>
                    <DashboardOverview />
                  </Suspense>
                }
              />

              <Route
                path={EVENTS_URL}
                element={
                  <Suspense fallback={<FullPageLoader />}>
                    <AllEvents />
                  </Suspense>
                }
              />

              <Route
                path={EVENTS_URL + "/manage/:eventId"}
                element={
                  <Suspense fallback={<FullPageLoader />}>
                    <ManageEvents />
                  </Suspense>
                }
              />

              <Route
                path={EVENTS_PARTICIPANTS_URL}
                element={
                  <Suspense fallback={<FullPageLoader />}>
                    <Participants />
                  </Suspense>
                }
              />
              <Route
                path={`${EVENTS_PARTICIPANTS_URL}/:eventId`}
                element={
                  <Suspense fallback={<FullPageLoader />}>
                    <ParticipantView />
                  </Suspense>
                }
              />
              <Route
                path={EVENTS_ARCHIVE_URL}
                element={
                  <Suspense fallback={<FullPageLoader />}>
                    <ArchivedParticipants />
                  </Suspense>
                }
              />
              <Route
                path={EVENTS_ANALYTICS_URL}
                element={
                  <Suspense fallback={<FullPageLoader />}>
                    <EventAnalytics />
                  </Suspense>
                }
              />

              <Route
                path={VENDORS_DIRECTORY_URL}
                element={
                  <Suspense fallback={<FullPageLoader />}>
                    <VendorDirectory />
                  </Suspense>
                }
              />
              <Route
                path={MY_VENDORS_URL}
                element={
                  <Suspense fallback={<FullPageLoader />}>
                    <MyVendor />
                  </Suspense>
                }
              />

              <Route
                path={SETTINGS_URL}
                element={
                  <Suspense fallback={<FullPageLoader />}>
                    <Settings />
                  </Suspense>
                }
              />
            </React.Fragment>
          </Route>
        </Route>

        {/* <Route path={"/onboarding"} element={<Onboarding />} /> */}
      </Routes>
    </Router>
  )
}
